import React from 'react';
import '../assets/css/ReportingCard.css';

const ReportingCard = ({ header, data, icon }) => {
    return (
        <div className="reporting-card">
            <div className="resume display-flex align-center">
                <div className="display-flex column text-container">
                    {icon && (
                        <i
                            className={`fa-solid ${icon} m-10 reporting-icon`}
                            style={{ color: "var(--secondary)" }}
                        />
                    )}

                    {data && (
                        <h3
                            className="m-5 text-secondary title-label-reporting data"
                            style={{ fontWeight: 900 }}
                        >
                            {data}
                        </h3>
                    )}

                    {/* Separator */}
                    <div className="separator"></div>

                    {header && (
                        <h4 className="m-5 text-primary header card-reporting">
                            {header}
                        </h4>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReportingCard;
