import React, {useState, useEffect, useRef, Fragment} from 'react';
import axios from 'axios';
import 'c3/c3.css';
import c3 from 'c3';
import '../assets/css/Productivity.css'; // Import CSS for styling
import {API_BASE_URL} from "../config";
import SearchBar from "../components/SearchDropdown";
import SearchDropdown from "../components/SearchDropdown";
import WIP from "../components/WIP";
import Table from "../components/Table";

const Productivity = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [data, setData] = useState([]);
    const chartRef = useRef(null);
    const [error, setError] = useState("Merci de sélectionner un projet");

    useEffect(() => {
        document.title = 'X | Productivité'
    }, []);
    // Fetch the list of projects
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_projects_distinct_with_finished_positions`)
            .then(response => {
                console.log(response.data)
                setProjects(response.data);
                console.log(response.message);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
                setError('Error fetching projects');
            });

    }, []);

    // Fetch productivity data when a project is selected
    useEffect(() => {
        if (selectedProject) {
            console.log(selectedProject);
            axios.post(`${API_BASE_URL}/get_productivity_positions`, {articleCode: selectedProject})
                .then(response => {
                    console.log("Data fetched:", response.data); // Log the response data
                    setData(response.data);
                    setError(null)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setError('Error fetching data');
                });
        }
    }, [selectedProject]);

    useEffect(() => {
        if (chartRef.current) {
            if (data.length > 0) {
                // Map month strings to French month names
                const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

                // Initialize array to store total hours and minutes for each month
                const totalMinutes = new Array(12).fill(0); // Store total time in minutes for each month
                const itemCount = Array(12).fill(0); // Count of items for each month


                data.forEach(item => {
                    if (item.task_month) { // Check if task_month is not null
                        const month = parseInt(item.task_month.split('-')[1], 10) - 1; // Extract month from 'YYYY-MM'
                        const [hours, minutes, seconds] = item.avg_time_spent.split(':').map(Number);
                        const totalMinutesForTask = hours * 60 + minutes + seconds / 60; // Convert to total minutes

                        if (month >= 0 && month < 12) {
                            totalMinutes[month] += totalMinutesForTask; // Sum minutes for the corresponding month
                            itemCount[month] += 1; // Increment the count of items for the month
                        }
                    }
                });
// Calculate the average total time for each month
                const avgTotalTimePerMonth = totalMinutes.map((total, index) => {
                    return itemCount[index] > 0 ? total / itemCount[index] : 0; // Avoid division by zero
                });

// Convert average total time to hours for the chart
                const avgData = ['Temps moyen', ...avgTotalTimePerMonth.map(mins => mins / 60)]; // Average in hours

// Prepare chart data for C3
                const chartData = {
                    columns: [avgData], // Use avgData instead of totalMinutes
                    categories: frenchMonths // x-axis labels
                };

// Generate the chart
                chartRef.current.__chart = c3.generate({
                    bindto: chartRef.current,
                    data: {
                        columns: chartData.columns,
                        type: 'bar'
                    },
                    axis: {
                        x: {
                            type: 'category',
                            categories: chartData.categories,
                            tick: {
                                rotate: -55, // Rotate labels 55 degrees
                                multiline: false
                            },
                            height: 130 // Increase height of x-axis for better label visibility
                        },
                        y: {
                            label: {
                                text: 'Temps moyen (Heures)', // Updated label to indicate average time
                                position: 'outer-middle'
                            },
                            tick: {
                                format: d => {
                                    const hours = Math.floor(d);
                                    const minutes = Math.round((d - hours) * 60);
                                    return `${hours}h ${minutes}m`; // Format the y-axis tick labels
                                }
                            }
                        }
                    },
                    tooltip: {
                        format: {
                            title: (d) => `Mois: ${frenchMonths[d]}`,
                            value: (value) => {
                                const hours = Math.floor(value);
                                const minutes = Math.round((value - hours) * 60);
                                return `${hours}h ${minutes}m`; // Format the tooltip values
                            }
                        },
                        grouped: false // Disable grouping tooltip
                    },
                    bar: {
                        width: {
                            ratio: 0.8 // Adjust the bar width
                        }
                    }
                });

            } else {
                // Clear the chart when there is no data
                chartRef.current.__chart = c3.generate({
                    bindto: chartRef.current,
                    data: {
                        columns: [['Heures totales', 0]], // Pass empty data
                        type: 'bar'
                    },
                    axis: {
                        x: {
                            type: 'category',
                            categories: [], // No categories
                        },
                        y: {
                            label: {
                                text: 'Heures totales', position: 'outer-middle'
                            }
                        }
                    }
                });
            }
        }
    }, [data, chartRef, selectedProject]);



    return (<div>
        <h2>Productivité</h2>
        <div>
            <div className={"container chart-container p-10 mb-20"}>
                <div className={"display-flex gap-10"}>
                    {
                        <div className={"search-bar container flex-1 m-0"}>
                            <SearchDropdown
                                data={projects.map(project => ({
                                    id: project.id,  // Use id for key
                                    value: project.intern_code,  // Use intern_code for value
                                    label: project.intern_code + " " + project.intern_name  // Display both intern_code and intern_name
                                }))}
                                onSelect={(selectedOption) => {
                                    console.log("Selected option:", selectedOption); // Log the selected option for debugging
                                    const selectedProject = selectedOption.value; // Use the intern_code directly
                                    console.log("Selected project intern_code:", selectedProject);
                                    setSelectedProject(selectedProject);  // Set the correct project intern_code
                                }}
                                placeholder="solutionWEB PRO - Création..."
                            />
                    </div>
                    }


                    <div className={"search-bar container flex-1 m-0 flex-direction-row display-flex select-project"}>
                        <select
                            className={"input flex-1 sea"}
                            id="project-select"
                            onChange={(e) => {
                                const selectedInternCode = projects.find(project => project.id === parseInt(e.target.value))?.intern_code;
                                setSelectedProject(selectedInternCode);
                            }}
                        >
                            <option value="">-- Sélectionner un projet --</option>
                            {projects.map(project => (<option key={project.id} value={project.id}>
                                {project.intern_code + " " + project.intern_name}
                            </option>))}
                        </select>
                    </div>
                </div>
            </div>
        </div>


        <div className={"chart-container"}>
            {error && <p>{error}</p>}
            {selectedProject && (<Fragment><p>{selectedProject}</p><div className={""} style={{height: "500px"}} ref={chartRef}/></Fragment>
            )}


    </div>

            {
                (data.length > 0) && (
                    <Fragment>
                        <div className={"chart-container mt-20"}>

                            <h2 className={"text-left"}><i className={"fa-solid fa-chart-line"}/> Moyennes </h2>
                            <Table
                                data={data}
                                showActions={false}
                                hidePagination={true}
                                headers={["N° Bexio commande", "Projet", "Client", "Date de fin de projet", "Temps passé", "Montant", "Tarif/Temps passé (prix à l'heure)"]}
                                headersData={['order_id', 'position_id_reporting', 'name_1_reporting', 'end_date', 'avg_time_spent', 'position_total', "avg_price_per_time_spent"]}
                            />
                            <div className={"display-flex"}>
                            </div>
                        </div>
                    </Fragment>

                )
            }

    </div>);
};

export default Productivity;
