import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchBar from "../components/SearchBar";
import Table from "../components/Table";
import { API_BASE_URL } from "../config"; // Update the path to config.js as needed

const PositionsEnCours = () => {
    const [published, setPublished] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatePositions, setUpdatePositions] = useState(0);
    const [sortBy, setSortBy] = useState({ column: null, ascending: true });

    const columnMapping = {
        "Progression": "progression",
        'N° Bexio commande': 'order_id',
        "Titre de la commande": "title",
        "Client": "client",
        "Date de création du projet": "created_date",
    };

    useEffect(() => {
        document.title = 'X | Projets en cours'
    }, []);
    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({ column: actualColumn, ascending });
    };

    const handleAction = (action, id) => {
        if (action === 'edit') {
            const url = `/positions/${id}`;
            window.open(url);
        }

        if (action === 'finish') {
            setLoading(true);
            axios.post(`${API_BASE_URL}/finish_position`, { id })
                .then(response => {
                    if (response.data.status === 'ok') {
                        setTotalPositions(response.data.datas);
                    }
                })
                .catch(error => {
                    console.error('Error finishing position:', error);
                })
                .finally(() => {
                    setLoading(false);
                    setUpdatePositions(updatePositions);
                });
        }

        if (action === 'delete') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette position ?");
            if (res) {
                setLoading(true);
                axios.post(`${API_BASE_URL}/delete_position`, { id })
                    .then(response => {
                    })
                    .catch(error => {
                        console.error('Error deleting position:', error);
                    })
                    .finally(() => {
                        setLoading(false);
                        setUpdatePositions(updatePositions + 1);
                    });
            }
        }
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_pending_positions`, { state: 'pending' })
            .then(res => {
                if (res.data.status === 'ok') {
                    const decodedData = res.data.datas.map(row => {
                        if (row.text) {
                            row.text = decodeHtmlEntities(row.text);
                        }
                        if (row.title) {
                            row.title = decodeHtmlEntities(row.title);
                        }
                        return row;
                    });
                    setPublished(decodedData);
                    setTotalPositions(decodedData);
                } else {
                    console.error('Erreur :', res.data.error);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [updatePositions]);

    const decodeHtmlEntities = (str) => {
        const element = document.createElement('div');
        if (str) {
            element.innerHTML = str;
            return element.textContent;
        }
        return str;
    };

    const publishedArray = Array.isArray(published) ? published : [];

    const customSort = (a, b) => {
        const safeCompare = (fieldA, fieldB) => {
            const valA = fieldA || ''; // Default to empty string
            const valB = fieldB || '';
            return valA.localeCompare(valB);
        };

        const parseDate = (dateString) => {
            // Handle null, undefined, or placeholder dates
            if (!dateString || dateString === "0000-00-00 00:00:00") return null;
            return new Date(dateString); // Convert to Date object
        };

        switch (sortBy.column) {
            case 'progression':
                return sortBy.ascending ? a.progress - b.progress : b.progress - a.progress;
            case 'order_id':
                return sortBy.ascending ? a.order_id - b.order_id : b.order_id - a.order_id;
            case 'title':
                return sortBy.ascending ? safeCompare(a.title, b.title) : safeCompare(b.title, a.title);
            case 'client':
                return sortBy.ascending ? safeCompare(a.name_1, b.name_1) : safeCompare(b.name_1, a.name_1);
            case 'created_date': {
                const dateA = parseDate(a.created_date);
                const dateB = parseDate(b.created_date);
                if (dateA && dateB) return sortBy.ascending ? dateA - dateB : dateB - dateA;
                if (!dateA) return sortBy.ascending ? 1 : -1;
                if (!dateB) return sortBy.ascending ? -1 : 1;
                return 0;
            }
            default:
                return 0;
        }
    };


    const sortedArray = [...publishedArray].sort(customSort);

    return (
        <div>
            <SearchBar totalPositions={totalPositions} setPublished={setPublished}
                       totalPositionsLength={publishedArray} positionLabel={'Projets en cours'} searchLabel={'projets trouvés'} />
            <Table
                data={sortedArray}
                handleSort={handleSort}
                sortBy={sortBy}
                showActions={true}
                handleAction={handleAction}
                headers={["Progression", "N° Bexio commande", "Titre de la commande", "Client", "Date de création du projet", "Actions"]}
                headersData={['progress', 'order_id', 'title', 'name_1', 'order_start_date']}
            />
        </div>
    )
}

export default PositionsEnCours;
