import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { API_BASE_URL } from "../config";

const Login = () => {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    useEffect(() => {
        document.title = 'X | Se connecter'
    }, []);
    const handleLogin = (e) => {
        e.preventDefault();

        axios.post(`${API_BASE_URL}/login`, { user, password })
            .then(response => {
                if (response.data.status === 'ok') {
                    axios.post(`${API_BASE_URL}/get_user_infos`, { user })
                        .then(res => {
                            console.log(user);

                            // Stockage des infos de l'utilisateur en localStorage
                            localStorage.token = response.data.token;
                            localStorage.user_id = res.data[0].id;
                            localStorage.user_mail = res.data[0].email;
                            localStorage.user_role = res.data[0].role;
                            // On met une majuscule au début du prénom et on stocke ça en localstorage
                            localStorage.user_name = user.charAt(0).toUpperCase() + user.slice(1);
                            console.log(localStorage);
                            window.location.replace('/');
                        });
                } else if (response.data.status === 'wrong-credentials') {
                    // Wrong credentials
                    setLoginError('Mot de passe ou nom d\'utilisateur incorrect.');
                } else if (response.data.status === 'user-not-found') {
                    // User not found
                    setLoginError('Cet utilisateur n\'existe pas.');
                } else if (response.data.status === 'account-locked') {
                    // Account locked
                    setLoginError(response.data.message);
                } else {
                    // Error from backend
                    setLoginError('Une erreur avec le serveur nodeJS s\'est produite.');
                }
            })
            .catch(error => {
                // Handle network errors
                console.error('Error:', error);
                setLoginError('An error occurred. Please try again later.');
            });
    };

    return (
        <div>
            <div className="login-container">
                <form className="login-form" onSubmit={handleLogin}>
                    <h2 className="title">Connexion à X</h2>
                    <div className="image-container">
                        <img src={'https://solution-digitale.ch/wp-content/uploads/2021/03/logo-solution-digitale-300-1.png'} alt="Solution Digitale Logo" className="logo-image"/>
                    </div>
                    <div className="input-container">
                        <div className="input-icon">
                            <i className="fas fa-user icon"></i>
                        </div>
                        <input className="input" type="text" placeholder="Utilisateur ou email@solution-digitale.ch" required
                               value={user} onChange={(e) => setUser(e.target.value)} />
                    </div>
                    <div className="input-container">
                        <div className="input-icon">
                            <i className="fas fa-lock icon"></i>
                        </div>
                        <input className="input" type="password" placeholder="Mot de passe" required
                               value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    {/* Display login error message */}
                    {loginError && <p className="error-message">{loginError}</p>}
                    <button className="btn-login" type="submit">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
