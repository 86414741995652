import React, {useEffect, useState} from 'react';
import WIP from "../components/WIP";
import axios from "axios";
import {API_BASE_URL} from "../config";
import '../assets/css/Emails.css';

const Emails = () => {
    const [email, setEmail] = useState('');
    const [result, setResult] = useState(null);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        document.title = 'X | Emails'
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${API_BASE_URL}/check_email`,{ email } )

            .then(response => {
                setResult(response.data);
                console.log(response)
            })
            .catch(error => {
                console.error('Error checking email:', error);
                setResult({ error: 'Failed to check email' });
            });
    };

    return (
        <div className="email-container">
            <h2 className="email-header">Email Checker</h2>
            <p className="email-paragraph">
                Cet outil vous permet de vérifier la validité d'une adresse email de plusieurs manières.
                En saisissant une adresse email, vous pouvez obtenir des informations importantes qui
                vous aideront à déterminer si l'adresse est valide et opérationnelle.
            </p>
            <form className="email-form" onSubmit={handleSubmit}>
                <div className={"seo-search-container search-bar display-flex "}>
                    <input className={"input search-bar-seo flex-2"} type="email" value={email} onChange={handleChange} required placeholder={"info@solution-digitale.ch"} autoFocus={true}/>
                    <button type="submit" className={"btn-generate-meta flex-1"}>Vérifier</button>
                </div>
            </form>
            {result && (
                <table className="email-table">
                    <tbody>
                    <tr>
                        <th>Validité</th>
                        <td>{result.valid ? 'Valide' : 'Invalide'}</td>
                    </tr>
                    {
                        result.mxRecords && (
                            <tr>
                                <th>MX Records</th>
                                <td>
                                    <ul>
                                        {result.mxRecords.map((record, index) => (
                                            <li key={index}>{record}</li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>
                        )
                    }

                    {
                        result.spf && (
                            <tr>
                                <th>SPF</th>
                                <td>{result.spf}</td>
                            </tr>
                        )
                    }

                    {
                        result.reason && (
                            <tr>
                                <th>Raison</th>
                                <td>{'Le domaine n\'a pas d\'enregistrements MX valides.'}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            )}
        </div>
    );
}


export default Emails;