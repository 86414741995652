import React, {useEffect} from 'react';
import WIP from "../components/WIP";

const Produits = () => {

    useEffect(() => {
        document.title = 'X | Produits'
    }, []);

    return (
        <div>
            <h2>Produits</h2>
            <WIP/>
        </div>
    );
};

export default Produits;