import { getDateRange, formatDate } from '../utils/dateUtils';
import React, { useEffect, useRef } from 'react';
import c3 from 'c3';
import 'c3/c3.css';

const ReportingChart = ({ data, selectedFilter, customStartDate, customEndDate }) => {
    const chartRef = useRef(null);

    const processData = (data) => {
        const dailySums = {};
        const dateRange = getDateRange(selectedFilter, customStartDate, customEndDate);

        dateRange.forEach(date => dailySums[date] = 0);

        data.forEach(({ finish_date, task_price_prorata }) => {
            const date = formatDate(new Date(finish_date));
            dailySums[date] += parseFloat(task_price_prorata) || 0;
        });

        return dailySums;
    };

    useEffect(() => {
        if (data?.length) {
            const processedData = processData(data);
            createChart(processedData);
        }
    }, [data, selectedFilter, customStartDate, customEndDate]);

    const createChart = (dailySums) => {
        const dates = Object.keys(dailySums);
        const amounts = Object.values(dailySums);

        // Parse dates from the custom format and pair them with amounts
        const dateAmountPairs = dates.map((date, index) => {
            const parsedDate = new Date(date);  // Parse the custom date format
            return { date: parsedDate, amount: amounts[index] };
        });

        // Sort the pairs by date in ascending order (oldest first)
        dateAmountPairs.sort((a, b) => a.date - b.date);

        // Extract sorted dates and amounts
        const sortedDates = dateAmountPairs.map(pair =>
            `${pair.date.getDate().toString().padStart(2, '0')}/${(pair.date.getMonth() + 1).toString().padStart(2, '0')}`
        );
        const sortedAmounts = dateAmountPairs.map(pair => pair.amount);

        c3.generate({
            bindto: chartRef.current,
            data: {
                x: 'x',
                columns: [['x', ...sortedDates], ['Montant', ...sortedAmounts]],
                type: 'area-spline',
            },
            axis: {
                x: {
                    type: 'category',
                    tick: {
                        format: (x) => {
                            const date = dateAmountPairs[Math.round(x)].date;  // Use parsed date for formatting
                            return !isNaN(date.getTime())
                                ? `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`
                                : 'Invalid Date';
                        },
                        count: Math.min(sortedDates.length, 15),
                    }
                }
            },
            point: {
                show: true
            }
        });
    };

    return <div ref={chartRef}></div>;


};

export default ReportingChart;
