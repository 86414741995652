import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState } from "react";

const Pagination = ({ totalPages, handlePageChange, maxDisplayPages }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const initialPage = Math.max(1, Math.min(parseInt(searchParams.get('p')) || 1, totalPages));

    const [currentPage, setCurrentPage] = useState(initialPage);

    const updatePage = (page) => {
        setCurrentPage(page);
        handlePageChange(page);
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('p', page);
        navigate({ search: searchParams.toString() });
    };

    const pagesToShow = Math.min(maxDisplayPages, totalPages);
    const startPage = Math.max(1, Math.min(currentPage - Math.floor(pagesToShow / 2), totalPages - pagesToShow + 1));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    const renderPages = () => {
        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    onClick={() => updatePage(i)}
                    className={currentPage === i ? 'active' : ''}
                >
                    {i}
                </button>
            );
        }
        return pages;
    };

    return (
        <div className="pagination" style={{ borderRadius: "0 0 5px 5px" }}>
            <button onClick={() => updatePage(1)} disabled={currentPage === 1}>
                &lt;&lt;
            </button>
            {currentPage > 1 && (
                <button onClick={() => updatePage(currentPage - 1)}>&lt;</button>
            )}
            {renderPages()}
            {currentPage < totalPages && (
                <button onClick={() => updatePage(currentPage + 1)}>&gt;</button>
            )}
            <button onClick={() => updatePage(totalPages)} disabled={currentPage === totalPages}>
                &gt;&gt;
            </button>
        </div>
    );
};

export default Pagination;
