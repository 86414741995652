import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import Table from "../components/Table";
import UserTimeAmountChart from "../components/UserTimeAmountChart";

const Renouvellements = () => {
    const [renewals1, setRenewals1] = useState([]);
    const [renewals2, setRenewals2] = useState([]);
    const [renewals3, setRenewals3] = useState([]);

    const params = new URLSearchParams(window.location.search);
    const startDate = params.get('start');
    const endDate = params.get('end');

    useEffect(() => {
        document.title = 'X | Renouvellements'
    }, []);
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_renewals_details`, { startDate, endDate })
            .then(response => {
                const renewalData = response.data;  // Assuming the data is an array

                // Temporary arrays to hold renewals for each year
                const tempRenewals1 = [];
                const tempRenewals2 = [];
                const tempRenewals3 = [];

                // Loop through each renewal item and categorize it based on product_code
                renewalData.forEach(item => {


                    if (item.title.includes('1') && !item.title.includes('2') &&!item.title.includes('3') ) {
                        tempRenewals1.push(item);
                    } else if (item.title.includes('2')) {
                        tempRenewals2.push(item);
                    } else if (item.title.includes('tacite')) {
                        tempRenewals3.push(item);
                    }
                });

                console.log(tempRenewals1)
                // Update state with categorized data
                setRenewals1(tempRenewals1);
                setRenewals2(tempRenewals2);
                setRenewals3(tempRenewals3);

            })
            .catch(error => {
                console.error('Error fetching renewal details:', error);
            });
    }, []);  // Empty dependency array means this runs once when the component mounts



    return (<div>
        <h2>Renouvellements</h2>
        <div className={'display-flex'} style={{justifyContent: "flex-end"}}>
            <div className="flex-1 report-chart mb-20"
                 style={{gap: "20px", justifyContent: "center"}}>
                <i className={"fa-solid fa-info info-bulle"}/>
                Cette vue répertorie les renouvellements et les résiliations.
            </div>
        </div>

        <div className={'report-chart'} style={{display: "grid"}}>
            <div className={"display-grid"}>
                <Table
                    hidePagination
                    title={"Abonnements 1ère année"}
                    data={renewals1}
                    headersData={['name_1', 'position_reporting', 'renouvellement_start', 'position_total']}
                    headers={["Client", "Nom du projet", 'Date de début', "Montant"]}
                />
            </div>

            <div className={"display-grid"}>
                <Table
                    title={"Abonnements 2e année"}
                    data={renewals2}
                    hidePagination
                    headersData={['name_1', 'position_reporting', 'renouvellement_start', 'position_total']}
                    headers={["Client", "Nom du projet", 'Date de début', "Montant"]}
                />
            </div>

            <div className={"display-grid"}>
                <Table
                    title={"Renouvellements 3e année"}
                    data={renewals3}
                    hidePagination
                    headersData={['name_1', 'position_reporting', 'renouvellement_start', 'position_total']}
                    headers={["Client", "Nom du projet", 'Date du renouvellement', "Montant"]}
                />
            </div>
        </div>
    </div>);
};

export default Renouvellements;