import React from 'react';
import { Chart } from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

function UserTimeAmountChart({ userTimeMap, label1, label2, labelX, labelY }) {
    const labels = Object.keys(userTimeMap).sort(); // Get sorted usernames
    const timeData = labels.map(username => userTimeMap[username].totalMinutes / 60); // Time in hours
    const amountData = labels.map(username => userTimeMap[username].totalPrice);

    const data = {
        labels,
        datasets: [
            {
                label: label1 ,
                data: timeData,
                backgroundColor: 'rgba(75, 192, 192, 0.8)',  // Adjust opacity
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                borderRadius: 5,  // Rounded corners
                hoverBackgroundColor: 'rgba(75, 192, 192, 0.6)',
                hoverBorderColor: 'rgba(75, 192, 192, 1)'
            },
            {
                label: label2,
                data: amountData,
                backgroundColor: 'rgba(153, 102, 255, 0.8)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 2,
                borderRadius: 10,
                hoverBackgroundColor: 'rgba(153, 102, 255, 0.6)',
                hoverBorderColor: 'rgba(153, 102, 255, 1)'
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 14,  // Increase font size
                        family: 'Arial'
                    },
                    color: '#333'  // Legend color
                }
            },
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark tooltip background
                titleFont: {
                    size: 16
                },
                bodyFont: {
                    size: 14
                }
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: labelX,
                    font: {
                        size: 16,
                        weight: 'bold'
                    },
                    color: '#444'  // Axis title color
                },
                ticks: {
                    font: {
                        size: 14
                    },
                    color: '#666'  // X-axis label color
                },
                grid: {
                    display: false  // Disable vertical grid lines
                }
            },
            y: {
                title: {
                    display: true,
                    text: labelY,
                    font: {
                        size: 16,
                        weight: 'bold'
                    },
                    color: '#444'  // Axis title color
                },
                ticks: {
                    font: {
                        size: 14
                    },
                    color: '#666'  // Y-axis label color
                },
                beginAtZero: true
            }
        }
    };


    return <Bar data={data} options={options} />;
}

export default UserTimeAmountChart;
