import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config';
import '../assets/css/SEOChecker.css';
import SEODonutChart from '../components/SEODonutChart';

const SEOChecker = () => {
    const [url, setUrl] = useState('');
    const [seoData, setSeoData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [titleError, setTitleError] = useState(null);
    const [titleWidth, setTitleWidth] = useState(0);

    const measureTextWidth = (text, font = '18px Arial') => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        return context.measureText(text).width;
    };

    useEffect(() => {
        document.title = 'X | SEO Check'
    }, []);
    const fetchSEOData = () => {
        setLoading(true);
        setError(null);

        let formattedUrl = url;
        if (!/^https?:\/\//i.test(url)) {
            formattedUrl = `https://${url}`;
        }

        axios.post(`${API_BASE_URL}/seo_check`, { url: formattedUrl })
            .then(response => {
                const data = response.data;

                console.log(response.data)
                setSeoData(response.data);

                const titleWidth = measureTextWidth(data.title, '18px Arial');
                setTitleWidth(titleWidth);
                data.titleWidth = titleWidth;

                if (titleWidth > 580) {
                    setTitleError('Le titre dépasse 580 pixels.');
                } else if (titleWidth < 480) {
                    setSeoData(data);
                    setTitleError("Le titre est trop court (recommandé: Entre 480 et 580px)");
                }

                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données SEO:', error);
                setError('Aucune donnée n\'a été trouvée pour l\'URL saisie.');
                setLoading(false);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchSEOData();
    };

    const evaluateSEO = (data) => {
        const {
            title, metaDescription, h1, h2, h3, images, imagesWithAlt, responsive, loadTime, schema,
            internalLinks, externalLinks, textInLinks, textInParagraphs, keywordInMetaDescription,
            keywordInH1, contentLength, readabilityScore, urlLength, urlKeyword, mobileUsability,
            robotsTxt, https, openGraphTags, twitterCards
        } = data;

        const score = ([
            !!title, !!metaDescription, h1 > 0, h2 > 0, h3 > 0, images > 0, imagesWithAlt === images,
            responsive, loadTime < 3000, !!schema, internalLinks > 0, externalLinks > 0, textInLinks > 0, textInParagraphs > 0, keywordInMetaDescription, keywordInH1 > 0, contentLength > 300, readabilityScore < 60,
            urlLength < 100, urlKeyword, mobileUsability, robotsTxt, https, openGraphTags, twitterCards
        ].filter(Boolean).length / 25) * 100;

        return score;
    };

    const getAdvice = (data) => {
        const advices = [];
        const {
            title, metaDescription, h1, h2, h3, images, imagesWithAlt, responsive, loadTime, schema,
            internalLinks, externalLinks, textInLinks, textInParagraphs, keywordInMetaDescription,
            keywordInH1, contentLength, readabilityScore, urlLength, urlKeyword, mobileUsability,
            robotsTxt, https, openGraphTags, twitterCards
        } = data;

        if (!title) advices.push("Le titre est manquant.");
        if (!metaDescription) advices.push("La meta description est manquante.");
        if (h1 === 0) advices.push("Il manque une balise H1.");
        if (h2 === 0) advices.push("Il manque des balises H2.");
        if (h3 === 0) advices.push("Il manque des balises H3.");
        if (images === 0) advices.push("Il manque des images.");
        if (imagesWithAlt !== images) advices.push("Certaines images n'ont pas d'attribut alt.");
        if (!responsive) advices.push("Le site n'est pas responsive.");
        if (loadTime >= 3000) advices.push("Le temps de chargement est trop long.");
        if (!schema) advices.push("Le balisage schema.org est manquant.");
        if (internalLinks === 0) advices.push("Il manque des liens internes.");
        if (externalLinks === 0) advices.push("Il manque des liens externes.");
        if (textInLinks === 0) advices.push("Il n'y a pas de texte dans les liens.");
        if (textInParagraphs === 0) advices.push("Il n'y a pas de texte dans les paragraphes.");
        if (!keywordInMetaDescription) advices.push("Le mot clé cible est absent de la meta description.");
        if (!keywordInH1) advices.push("Le mot clé cible est absent de la balise H1.");
        if (contentLength <= 300) advices.push("Le contenu est trop court.");
        if (readabilityScore >= 60) advices.push("Le contenu est trop difficile à lire.");
        if (urlLength >= 100) advices.push("L'URL est trop longue.");
        if (!urlKeyword) advices.push("Le mot clé cible est absent de l'URL.");
        if (!mobileUsability) advices.push("Le site n'est pas optimisé pour mobile.");
        if (!robotsTxt) advices.push("Le fichier robots.txt est manquant.");
        if (!https) advices.push("Le site n'utilise pas HTTPS.");
        if (!openGraphTags) advices.push("Les balises Open Graph sont manquantes.");
        if (!twitterCards) advices.push("Les balises Twitter Cards sont manquantes.");

        return advices;
    };

    return (
        <div className="seo-checker-container">
            <h2 className="seo-checker-title">SEO Checker</h2>

            <div className="seo-search-container search-bar">
                <form onSubmit={handleSubmit} className="seo-checker-form">
                    <input
                        autoFocus={true}
                        className="seo-input search-bar-seo"
                        type="url"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Entrez une URL à vérifier"
                    />
                    <button onClick={handleSubmit} type="submit" className="btn-generate-meta">Vérifier SEO</button>
                </form>
                {loading && <p className="seo-loading">Calcul du score SEO</p>}
            </div>
            <div className={'container'}>
                <div className="seo-result-container">
                    {error && <p className="seo-error">{error}</p>}
                    {seoData && (
                        <div className="seo-data">
                            <SEODonutChart score={evaluateSEO(seoData).toFixed(2)} />
                            <div className="seo-advice-container mb-20 p-15" style={{backgroundColor: "white", borderRadius: "15px"}}>
                                <h3 className="seo-advice-title text-left"><i className="fa-sharp fa-solid fa-circle-info" style={{color: "deepskyblue", marginRight: 10}}></i>Pistes
                                    d'amélioration :</h3>
                                <div className="text-left">
                                    {getAdvice(seoData).map((advice, index) => (
                                        <p key={index} className="seo-advice-item">- {advice}</p>
                                    ))}
                                </div>
                            </div>

                            <div className={'container p-20 background-'}>
                                <h3 className="seo-advice-title text-left"><i className="fa-sharp fa-solid fa-fire"
                                                                              style={{
                                                                                  color: "orangered",
                                                                                  marginRight: 10
                                                                              }}></i>Résumé
                                    des points forts :</h3>
                                <table className="seo-table">
                                    <tbody>
                                    <tr>
                                        {
                                            (titleWidth < 580 && titleWidth > 480) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Titre</td>
                                        <td>{seoData.title} (longueur : {titleWidth}px) {titleError}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.metaDescription) ? (
                                                <>
                                                    <td><i className="fa-solid fa-check color-primary"></i></td>
                                                    <td>Description Meta</td>
                                                    <td>{seoData.metaDescription}</td>
                                                </>
                                            ) : (
                                                <>
                                                    <td><i className="fa-solid fa-xmark"
                                                           style={{color: "orangered"}}></i></td>
                                                    <td>Description Meta</td>
                                                    <td>Il manque une description.</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.h1 > 0) ? (
                                                <>
                                                    <td><i className="fa-solid fa-check color-primary"></i></td>
                                                    <td>Balises H1</td>
                                                    <td>{seoData.h1}</td>
                                                </>
                                            ) : (
                                                <>
                                                    <td><i className="fa-solid fa-xmark"
                                                           style={{color: "orangered"}}></i></td>
                                                    <td>Balises H1</td>
                                                    <td>Pas assez de H1 ({seoData.h1}).</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.h2 > 0) ? (
                                                <>
                                                    <td><i className="fa-solid fa-check color-primary"></i></td>
                                                    <td>Balises H2</td>
                                                    <td>{seoData.h2}</td>
                                                </>
                                            ) : (
                                                <>
                                                    <td><i className="fa-solid fa-xmark"
                                                           style={{color: "orangered"}}></i></td>
                                                    <td>Balises H2</td>
                                                    <td>Pas assez de H2 ({seoData.h2}).</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.h3 > 0) ? (
                                                <>
                                                    <td><i className="fa-solid fa-check color-primary"></i></td>
                                                    <td>Balises H3</td>
                                                    <td>{seoData.h3}</td>
                                                </>
                                            ) : (
                                                <>
                                                    <td><i className="fa-solid fa-xmark"
                                                           style={{color: "orangered"}}></i></td>
                                                    <td>Balises H3</td>
                                                    <td>Pas assez de H3 ({seoData.h3}).</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.images > 0) ? (
                                                <>
                                                    <td><i className="fa-solid fa-check color-primary"></i></td>
                                                    <td>Images</td>
                                                    <td>{seoData.images}</td>
                                                </>
                                            ) : (
                                                <>
                                                    <td><i className="fa-solid fa-xmark"
                                                           style={{color: "orangered"}}></i></td>
                                                    <td>Images</td>
                                                    <td>Pas assez d'images ({seoData.images}).</td>
                                                </>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.imagesWithAlt > 0) ? (
                                                <>
                                                    <td><i className="fa-solid fa-check color-primary"></i></td>
                                                    <td>Images avec texte alternatif</td>
                                                    <td>{seoData.imagesWithAlt}</td>
                                                </>
                                            ) : (
                                                <>
                                                    <td><i className="fa-solid fa-xmark"
                                                           style={{color: "orangered"}}></i></td>
                                                    <td>Images avec texte alternatif</td>
                                                    <td>Pas assez d'images avec texte alternatif
                                                        ({seoData.imagesWithAlt}).
                                                    </td>
                                                </>
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.titleWidth > 0) ? (
                                                <>
                                                    <td><i className="fa-solid fa-check color-primary"></i></td>
                                                    <td>Largeur du titre</td>
                                                    <td>{seoData.titleWidth}px</td>
                                                </>
                                            ) : (
                                                <>
                                                    <td><i className="fa-solid fa-xmark"
                                                           style={{color: "orangered"}}></i></td>
                                                    <td>Largeur du titre</td>
                                                    <td>{Math.floor(seoData.titleWidth)} px</td>
                                                </>
                                            )
                                        }
                                    </tr>

                                    <tr>
                                        {
                                            (seoData.responsive) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Adaptabilité mobile</td>
                                        <td>{seoData.responsive ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.loadTime < 2000) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Temps de chargement</td>
                                        <td>{seoData.loadTime.toFixed(2)} ms</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.schema) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Balisage schema.org</td>
                                        <td>{seoData.schema ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.internalLinks > 0) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Liens internes</td>
                                        <td>{seoData.internalLinks}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.externalLinks > 0) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Liens externes</td>
                                        <td>{seoData.externalLinks}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.textInLinks.length > 0) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Texte dans les liens</td>
                                        <td>{seoData.textInLinks}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.textInParagraphs.length > 0) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Texte dans les paragraphes</td>
                                        <td>{seoData.textInParagraphs}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (!seoData.keywordInMetaDescription) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Mot clé dans la meta description</td>
                                        <td>{seoData.keywordInMetaDescription ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (!seoData.keywordInH1) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Mot clé dans les balises H1</td>
                                        <td>{seoData.keywordInH1 ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.contentLength > 0) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Longueur du contenu</td>
                                        <td>{seoData.contentLength} caractères</td>
                                    </tr>

                                    <tr>
                                        {
                                            (seoData.readabilityScore > 0) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Score de lisibilité</td>
                                        <td>{seoData.readabilityScore.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.urlLength > 0) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Longueur de l'URL</td>
                                        <td>{seoData.urlLength} caractères</td>
                                    </tr>
                                    <tr>
                                        {
                                            (!seoData.urlKeyword) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Mot clé dans l'URL</td>
                                        <td>{seoData.urlKeyword ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.mobileUsability) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Utilisabilité mobile</td>
                                        <td>{seoData.mobileUsability ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.robotsTxt) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Robots.txt</td>
                                        <td>{seoData.robotsTxt ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.https) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>HTTPS</td>
                                        <td>{seoData.https ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.openGraphTags) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Balises Open Graph</td>
                                        <td>{seoData.openGraphTags ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        {
                                            (seoData.twitterCards) ? (
                                                <td><i className="fa-solid fa-check color-primary"></i></td>
                                            ) : (
                                                <td><i className="fa-solid fa-xmark color-orangered"></i>
                                                </td>
                                            )
                                        }
                                        <td>Twitter Cards</td>
                                        <td>{seoData.twitterCards ? 'Oui' : 'Non'}</td>
                                    </tr>
                                    <tr>
                                        <td><i className="fa-sharp fa-light fa-seal color-primary"></i>
                                    </td>

                                    <td>Score SEO</td>
                                        <td>{evaluateSEO(seoData).toFixed(2)}%</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SEOChecker;
