import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import ReportingChart from "../components/ReportingChart";
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/css/Reporting.css';
import '../assets/css/ReportingGrid.css';
import {fr} from 'date-fns/locale';
import ReportingCard from "../components/ReportingCard";
import {getDateRange} from '../utils/dateUtils';
import Tasks from "../components/Tasks";
import {NavLink} from "react-router-dom";
import UserTimeAmountChart from "../components/UserTimeAmountChart";

registerLocale('fr', fr);

const Reporting = () => {
    const [dailyAvg, setDailyAvg] = useState(0);  // Initialize to 0
    const [totalAmount, setTotalAmount] = useState(0);  // Initialize to 0
    const [totalTasks, setTotalTasks] = useState([]);
    const [datasFound, setDatasFound] = useState([]);
    const [totalRenewalAmount, setTotalRenewalAmount] = useState(0);  // Initialize to 0
    const [selectedFilter, setSelectedFilter] = useState('thisWeek'); // Default filter
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formattedStartDate, setFormattedStartDate] = useState(new Date());
    const [formattedEndDate, setFormattedEndDate] = useState(new Date());
    const [proratedData, setProratedData] = useState([]);
    const [isCustom, setIsCustom] = useState(false);
    const [renewalYear1, setRenewalYear1] = useState(0);  // Initialize to 0
    const [renewalYear2, setRenewalYear2] = useState(0);  // Initialize to 0
    const [renewalYear3, setRenewalYear3] = useState(0);  // Initialize to 0
    const datePickerRef = useRef(null);

    useEffect(() => {
        document.title = 'X | Vue de la production'
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsCustom(false);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // Group tasks by user and calculate total time spent per user
    const userTimeMap = totalTasks.reduce((acc, {username, time_spent, task_price_prorata}) => {
        const [hours, minutes, seconds] = time_spent.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes + Math.floor(seconds / 60);

        if (!acc[username]) {
            acc[username] = {totalMinutes: 0, totalPrice: 0}; // Initialize if user doesn't exist
        }

        acc[username].totalMinutes += totalMinutes; // Add time in minutes
        acc[username].totalPrice += task_price_prorata; // Sum up task_price_prorata

        return acc;
    }, {});

    useEffect(() => {
        if (selectedFilter !== 'custom') {
            fetchData(selectedFilter); // Fetch data based on selected filter
        } else {
            fetchData('custom', startDate, endDate); // Fetch data for custom duration
        }
    }, [selectedFilter, startDate, endDate, formattedStartDate, formattedEndDate]);

    useEffect(() => {
        // Calculate the daily average whenever the total amounts or prorated data change
        const calculateUniqueTaskDays = () => {
            const uniqueDays = new Set();
            proratedData.forEach(task => {
                if (task.finish_date) {
                    const finishDate = new Date(task.finish_date).toISOString().split('T')[0];
                    uniqueDays.add(finishDate);
                }
            });
            return uniqueDays.size || 1;  // Avoid division by zero
        };

        const numOfDays = calculateUniqueTaskDays();
        const totalWithRenewal = totalAmount + totalRenewalAmount;
        const avg = numOfDays > 0 ? totalWithRenewal / numOfDays : 0;
        setDailyAvg(avg);

    }, [totalAmount, totalRenewalAmount, proratedData]);

    const fetchData = (filter, customStartDate, customEndDate) => {
        const dateRange = getDateRange(filter, customStartDate, customEndDate);
        const formattedStartDate = dateRange[0];
        const formattedEndDate = dateRange[dateRange.length - 1];

        setFormattedStartDate(formattedStartDate)
        setFormattedEndDate(formattedEndDate)

        // API call: Get prorated task value per position
        axios.post(`${API_BASE_URL}/get_prorata_for_position`, {
            startDate: formattedStartDate, endDate: formattedEndDate
        })
            .then(response => {
                const proratedData = response.data.datas;
                setProratedData(proratedData);
                setTotalTasks(proratedData);
                setDatasFound(proratedData);

                // Calculate the sum of the prorated amounts
                const totalAmount = proratedData.reduce((sum, item) => sum + (parseFloat(item.task_price_prorata) || 0), 0);
                setTotalAmount(totalAmount);
            })
            .catch(error => {
                console.error('Error retrieving prorated task data:', error);
            });

        // API call: Get renewal amount data
        axios.post(`${API_BASE_URL}/get_renewals_details`, {
            startDate: formattedStartDate, endDate: formattedEndDate
        })
            .then(res => {
                const renewalData = res.data;

                // Extract all position_total values into an array
                const totalRenewalAmountArray = renewalData.map(item => item.position_total);
                setTotalRenewalAmount(totalRenewalAmountArray);

                // Calcul du total de renouvellement
                const totalRenewal = totalRenewalAmountArray.reduce((sum, amount) => sum + amount, 0);
                setTotalRenewalAmount(totalRenewal);

                let renewalYear1 = 0, renewalYear2 = 0, renewalYear3 = 0;
                renewalData.forEach(item => {

                    if (item.title.includes('1') && !item.title.includes('2') && !item.title.includes('3')) {
                        renewalYear1 += item.position_total;
                    } else if (item.title.includes('2')) {
                        renewalYear2 += item.position_total;
                    } else if (item.title.includes('Renouvellement')) {
                        renewalYear3 += item.position_total;
                    } else {
                    }
                });

                setRenewalYear1(renewalYear1);
                setRenewalYear2(renewalYear2);
                setRenewalYear3(renewalYear3);
            })
            .catch(error => {
                console.error('Erreur de récupération des renouvellements :', error);
            });
    };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setIsCustom(filter === 'custom');
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setSelectedFilter('custom');
            setIsCustom(true);
        }
    };


    return (<div>
        <h2>Vue de la production</h2>
        <div className={'display-flex'} style={{justifyContent: "flex-end"}}>

            <div className="flex-1 report-chart mb-20"
                 style={{gap: "20px", justifyContent: "center"}}>
                <i className={"fa-solid fa-info info-bulle"}/> Cette vue répertorie les revenus générés pendant la
                période
                sélectionnée.
            </div>
        </div>

        <div className="parent display-flex mb-20">
            <div className="flex-1">
                <ReportingCard header={"Revenus de production pure"}
                               data={`${(totalAmount).toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-sack-dollar'}/>
            </div>
            <div className="flex-1">
                <ReportingCard header={"Abonnements 1ère année"}
                               data={`${renewalYear1.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-regular fa-money-bill'}/>
            </div>

            <div className="flex-1">
                <ReportingCard header={"Abonnements 2e année"}
                               data={`${renewalYear2.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-regular fa-money-bills'}/>
            </div>

            <div className="flex-1">
                <ReportingCard header={"Renouvellements tacites"}
                               data={`${renewalYear3.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-regular fa-refresh'}/>
            </div>


            <div className="flex-1">
                <ReportingCard header={"Tâches terminées"} data={totalTasks.length > 0 && totalTasks.length}
                               icon={'fa-list'}/>
            </div>
            <div className="flex-1">
                <ReportingCard header={"Moyenne quotidienne"}
                               data={`${dailyAvg.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-calendar-check'}/>
            </div>
            <div className="flex-1">
                <ReportingCard
                    header={"Total"}
                    data={`${(totalAmount + totalRenewalAmount).toLocaleString('en-CH', {
                        minimumFractionDigits: 2, maximumFractionDigits: 2
                    })} CHF`}
                    icon={'fa-sack-dollar'}
                />
            </div>
        </div>

        <div className="parent second-parent-container-reporting">
            <div className="display-flex position-relative report-chart flex-direction-row flex-2"
                 style={{alignItems: "unset"}}>
                <div className="flex-4 display-flex flex-direction-column p-20">
                    <div className={"p-10"}>

                        <ReportingChart data={proratedData} selectedFilter={selectedFilter}
                                        customStartDate={startDate}
                                        customEndDate={endDate}/>
                    </div>
                    <div className={"display-grid p-10"}>

                        <div className={"display-flex mb-10"}>
                            <button
                                className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisWeek' ? 'active-reporting' : ''}`}
                                onClick={() => handleFilterChange('thisWeek')}
                            >
                                Cette semaine
                            </button>
                            <button
                                className={`margin-0 flex-1 filter-btn ${selectedFilter === 'lastWeek' ? 'active-reporting' : ''}`}
                                onClick={() => handleFilterChange('lastWeek')}
                            >
                                La semaine passée
                            </button>
                        </div>
                        <div className={"display-flex mb-10"}>

                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisMonth' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('thisMonth')}
                            >
                                Ce mois
                            </button>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'lastMonth' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('lastMonth')}
                            >
                                Le mois passé
                            </button>

                        </div>
                        <div className={"display-flex mb-10"}>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisYear' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('thisYear')}
                            >
                                Cette année
                            </button>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'lastYear' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('lastYear')}
                            >
                                L'année passée
                            </button>

                        </div>
                        <div className={"display-flex mb-10"}>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn-custom ${selectedFilter === 'custom' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('custom')}
                            >

                                Durée personnalisée
                            </button>
                        </div>
                        {isCustom && (<div className="date-picker-container" ref={datePickerRef}>
                            <DatePicker
                                locale="fr"
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                calendarStartDay={1} // Monday as the first day of the week
                            />
                        </div>)}
                    </div>
                </div>

                <div className="p-10 flex-1 vertical-separator text-left">

                    <div>
                        <NavLink
                            to={`/renouvellements?start=${formattedStartDate}&end=${formattedEndDate}`}
                            className="text-decoration-none"
                            target="_blank"
                        >
                            <h2 style={{color: "#35365E"}}>Renouvellements

                            <i style={{fontSize: ".8rem"}} className={"ml-10 fa-solid fa-external-link"}/></h2>
                        </NavLink>
                        <hr/>
                        <div className={"display-grid text-left"}>

                            <h4>
                                1e année
                            </h4>
                            <div>{renewalYear1.toLocaleString('en-CH', {
                                minimumFractionDigits: 2, maximumFractionDigits: 2
                            })} CHF
                            </div>
                            <h4>2e année</h4>
                            <div>{renewalYear2.toLocaleString('en-CH', {
                                minimumFractionDigits: 2, maximumFractionDigits: 2
                            })} CHF
                            </div>
                            <h4>Renouvellements tacites</h4>
                            <div>{renewalYear3.toLocaleString('en-CH', {
                                minimumFractionDigits: 2, maximumFractionDigits: 2
                            })} CHF
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="display-flex position-relative report-chart flex-direction-row flex-1"
                 style={{alignItems: "unset", justifyContent: "flex-start"}}>
                <h2 style={{color: "#35365E"}}>Collaborateurs</h2>
            <UserTimeAmountChart userTimeMap={userTimeMap} label1={'Temps total (heures)'} label2={'Montant total (CHF)'} />
            </div>
        </div>
        {

            <div className="display-flex position-relative report-chart flex-direction-row flex-1"
                 style={{alignItems: "unset", justifyContent: "flex-start"}}>

                <div className="report-chart-wrapper">
                    <h2 style={{color: "#35365E"}}>Personnel</h2>
                    <hr/>
                    <table className="report-table">
                        <thead>
                        <tr>
                            <th>Nom d'utilisateur</th>
                            <th>Temps total</th>
                            <th>Montant total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.entries(userTimeMap)
                            .sort(([a], [b]) => a.localeCompare(b)) // Sort by username
                            .map(([username, {totalMinutes, totalPrice}]) => (<tr key={username}>
                                <td>{username}</td>
                                <td>{Math.floor(totalMinutes / 60)} heures {totalMinutes % 60} minutes</td>
                                <td>{totalPrice.toFixed(2)} CHF</td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>}


        <div className="parent flex-direction-column">
            <div className={"reporting-chart display-flex flex-direction-column"}>
                <h2>Détail des tâches terminées</h2>

                <Tasks

                    searchBar={true}
                    searchLabel={"tâches terminées trouvées."}
                    title={`Tâches pour la période ${formattedStartDate} - ${formattedEndDate}` }
                    tasks={datasFound}
                    headersData={["name_1", "title", "article_code", "time_spent_reporting", "task_price_prorata", "finish_date_reporting", "username_reporting"]}
                    headers={["Client", "Tâche", "Code article", "Temps passé", "Prix", "Date", "Personne"]}
                />
            </div>
        </div>
    </div>);
};

export default Reporting;
