import React, {Fragment, useEffect, useState} from 'react';
import axios from 'axios';
import {NavLink, useParams} from "react-router-dom";
import ExportSaveButtons from "../components/ExportSaveButtons";
import OpenPositions from "../components/OpenPositions";
import {API_BASE_URL} from "../config";
import '../assets/css/Position.css'
import Tasks from "../components/Tasks";
import ClosedPositions from "../components/ClosedPositions";

const PositionDetail = () => {
    const {order_id} = useParams(); // Extract order_id from URL params
    const [position, setPosition] = useState(null);
    const [loading, setLoading] = useState(true);
    const [positionNote, setPositionNote] = useState('');
    const [finishedTasks, setFinishedTasks] = useState([])
    const [inProgressTasks, setInProgressTasks] = useState([]);
    const [isOpenProgressTask, setIsOpenProgressTask] = useState(true);
    const [isOpenFinishedTask, setIsOpenFinishedTask] = useState(false);
    const [isOpenOpenPositions, setIsOpenOpenPositions] = useState(false);
    const [isOpenClosedPositions, setIsOpenClosedPositions] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [totalTimeSpent, setTotalTimeSpent] = useState('00:00:00');
    const [totalEstimatedTime, setTotalEstimatedTime] = useState('00:00:00');
    const formatDate = (isoDate) => {
        const date = new Date(isoDate); // Create a Date object from the ISO string
        const day = String(date.getUTCDate()).padStart(2, '0'); // Get the day and pad with leading zero
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Get the month (0-11, hence +1) and pad
        const year = date.getUTCFullYear(); // Get the full year
        return `${day}/${month}/${year}`; // Return in the format DD/MM/YYYY
    };

    const toggleOpenInfo = () => {
        setIsOpenInfo(!isOpenInfo);
    };
    const toggleOpenPositions = () => {
        setIsOpenOpenPositions(!isOpenOpenPositions);
    };

    const toggleClosedPositions = () => {
        setIsOpenClosedPositions(!isOpenClosedPositions);
    };
    const toggleIsOpenProgressTask = () => {
        setIsOpenProgressTask(!isOpenProgressTask);
    };
    const toggleIsOpenFinishedTask = () => {
        setIsOpenFinishedTask(!isOpenFinishedTask);
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_positions/${order_id}`)
            .then(response => {
                console.log(response);
                const {data} = response;
                if (data.status === 'ok') {
                    console.log(data);

                    setPosition(data.datas);
                    setPositionNote(data.datas[0]?.production_note); // Optional chaining in case datas is empty


                    document.title = `X | ${data.datas[0]?.name_1} | ${data.datas[0]?.name_1}`
                } else {
                    console.error('Error fetching position details:', data.message);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [order_id]); // Fetch data when order_id changes

    useEffect(() => {
        const currentUrl = window.location.href;
        const position_id = currentUrl.split('/positions/')[1]; // Extract position_id from the URL

        axios.post(`${API_BASE_URL}/get_all_tasks`, { positionId: position_id })
            .then(res => {
                const tasks = res?.data?.datas || []; // Fallback to empty array if tasks are undefined

                if (Array.isArray(tasks)) {
                    // Helper function to calculate total time spent in HH:MM:SS format
                    const calculateTotalTimeSpent = (taskList) => {
                        const totalSeconds = taskList.reduce((total, task) => {
                            if (!task.time_spent) return total; // Skip if no time_spent

                            const [hours, minutes, seconds] = task.time_spent.split(':').map(Number);
                            return total + (hours * 3600) + (minutes * 60) + seconds;
                        }, 0);

                        const hours = Math.floor(totalSeconds / 3600);
                        const minutes = Math.floor((totalSeconds % 3600) / 60);
                        const seconds = totalSeconds % 60;

                        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                    };

                    // Calculate total time spent for all tasks
                    const totalTimeSpent = calculateTotalTimeSpent(tasks);

                    // Calculate total estimated time for in-progress tasks
                    const calculateTotalEstimatedTime = (taskList) => {
                        const totalMinutes = taskList.reduce((total, task) => {
                            if (!task.estimated_duration || parseInt(task.progress, 10) >= 100) return total; // Skip if no estimated_duration or progress >= 100

                            const [hours, minutes, seconds] = task.estimated_duration.split(':').map(Number);
                            return total + (hours * 3600) + (minutes * 60) + seconds;
                        }, 0);

                        const hours = Math.floor(totalMinutes / 60);
                        const minutes = totalMinutes % 60;

                        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                    };

                    const totalEstimatedTime = calculateTotalEstimatedTime(tasks);
                    console.log('totalEstimatedTime')
                    console.log(totalEstimatedTime)
                    // Update state with the calculated values
                    setTotalTimeSpent(totalTimeSpent);
                    setTotalEstimatedTime(totalEstimatedTime); // Assuming you have a state variable for estimated time
                    setFinishedTasks(tasks.filter(task => parseInt(task.progress, 10) === 100));
                    setInProgressTasks(tasks.filter(task => parseInt(task.progress, 10) < 100));
                } else {
                    console.error('Tasks is not an array:', tasks);
                }
            })
            .catch(error => console.error('Error fetching tasks:', error));

    }, [order_id])

    const fetchTasks = () => {
        const position_id = window.location.href.split('/positions/')[1]; // Extract position_id from the URL

        axios.post(`${API_BASE_URL}/get_all_tasks`, {positionId: position_id})
            .then(res => {
                const tasks = res?.data?.datas || []; // Fallback to empty array if tasks are undefined

                if (Array.isArray(tasks)) {
                    // Helper function to calculate total time spent in HH:MM:SS format
                    const calculateTotalTimeSpent = (taskList) => {
                        const totalSeconds = taskList.reduce((total, task) => {
                            if (!task.time_spent) return total; // Skip if no time_spent

                            const [hours, minutes, seconds] = task.time_spent.split(':').map(Number);
                            return total + (hours * 3600) + (minutes * 60) + seconds;
                        }, 0);

                        const hours = Math.floor(totalSeconds / 3600);
                        const minutes = Math.floor((totalSeconds % 3600) / 60);
                        const seconds = totalSeconds % 60;

                        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                    };

                    // Calculate total time spent for all tasks
                    const totalTimeSpent = calculateTotalTimeSpent(tasks);

                    // Update state with the total time spent
                    setTotalTimeSpent(totalTimeSpent);

                    // If you still need to set finished/in-progress tasks separately
                    setFinishedTasks(tasks.filter(task => parseInt(task.progress, 10) === 100));
                    setInProgressTasks(tasks.filter(task => parseInt(task.progress, 10) < 100));
                } else {
                    console.error('Tasks is not an array:', tasks);
                }
            })
            .catch(error => console.error('Error fetching tasks:', error));
    };


    const handleCommentSection = (value) => {
        // Update the local state with the new value
        setPositionNote(value);
        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        // Assuming you have an API endpoint to update the position note
        axios.post(`${API_BASE_URL}/update_position_note`, {
            note: value, id: positionId
        }).then(response => {
            const {data} = response;
            if (data.status === 'ok') {
            } else {
                console.error('Error updating position note:', data.message);
            }
        }).catch(error => {
            console.error('Error updating position note:', error);
        });
    };
    const deleteTasks = (position) => {
        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        const res = window.confirm("Voulez-vous vraiment supprimer toutes les tâches en cours ? Cela n'impactera pas les tâches déjà terminées.");

        if (res) {
            if (!positionId || isNaN(positionId)) {
                throw new Error('Invalid position ID in URL');
            }

            axios.post(`${API_BASE_URL}/delete_position_tasks`, {id: positionId})
                .then(response => {
                    fetchTasks();
                })
                .catch(error => {
                    console.error('Error fetching default tasks:', error);
                });
        }
    }


    // Fetch tasks when component mounts
    useEffect(() => {
        fetchTasks();
    }, [position]); // Fetch tasks when the position changes


    const createDefaultTasks = () => {

        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        if (!positionId || isNaN(positionId)) {
            throw new Error('Invalid position ID in URL');
        }

        axios.post(`${API_BASE_URL}/get_default_tasks`, {positionId})
            .then(response => {
                const defaultTasks = response.data.default_tasks; // It's an array

                if (defaultTasks.length > 0) {
                    const tasksArray = defaultTasks.map(task => task.title); // Assuming 'title' holds the task names

                    axios.post(`${API_BASE_URL}/set_default_tasks`, {tasks: tasksArray, positionId})
                        .then(() => {
                            // Fetch updated tasks after creating default tasks
                            fetchTasks();
                        })
                        .catch(error => {
                            console.error('Error creating tasks:', error);
                        });
                } else {
                    console.log('No default tasks found.');
                }
            })
            .catch(error => {
                console.error('Error fetching default tasks:', error);
            });

    };

    if (loading) {
        return <div>Chargement...</div>;
    }

    if (!position) {
        return <div>Aucune position trouvée</div>;
    }


    return (<Fragment>
        {position.map((position) => (<Fragment>

                {position.is_archived === 1 && <div>
                    <span className={"archived-div"}><p>🗑️ Attention, ce projet est archivé</p></span></div>}
                {position.progress === 100 && <div>
                        <span
                            className={"finished-div"}><p>🎉 Ce projet a été terminé le {formatDate(position.end_date ? position.end_date : position.updated_at)}.</p></span>
                </div>}
                <div key={position.id}>
                    <div>
                        <h1 dangerouslySetInnerHTML={{__html: position.name_1}}/>

                        <span className={"text-primary position-id-text"}>{position.order_id} - </span>
                        <span className={"text-primary position-id-text"}
                              dangerouslySetInnerHTML={{__html: position.title}}/><br/>
                        <hr/>
                        <div className={"position-container"}>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: localStorage.user_role === 'Administrateur' ? "space-between" : "initial"
                                }}>

                                <div style={{padding: "15px 0px 15px 0"}}>

                                    {/*<button className={"button-action"}
                                        style={{padding: "10px 15px", cursor: "pointer", margin: 0}}
                                        onClick={handleDropdownToggle}>
                                    <i className="fa-solid fa-user"/> {labelAddUser}
                                </button>*/}

                                    <Fragment>
                                        <button
                                            className={"button-action create-task"}
                                            style={{padding: "10px 15px", cursor: "pointer"}}
                                            onClick={() => createDefaultTasks(position)}>
                                            <i className="fa-regular fa-plus"
                                               style={{marginRight: "5px"}}></i>Créer les tâches par défaut
                                        </button>

                                        <button
                                            className={"button-action delete m-0"}
                                            onClick={() => deleteTasks(position)}
                                            style={{padding: "10px 15px", cursor: "pointer"}}
                                        >
                                            <i className="fa-solid fa-trash" style={{marginRight: "5px"}}></i>
                                            Supprimer toutes les tâches
                                        </button>
                                    </Fragment>
                                </div>
                                <div style={{padding: "15px 0px"}}>
                                    <div className={"button-action m-0 display-flex gap-10"} style={{borderRadius: 5}}>
                                        <span style={{fontSize: "1.3rem"}}>Temps passé : {totalTimeSpent}</span>
                                        <span style={{fontSize: "1.3rem"}}>Temps planifié restant : {totalEstimatedTime}</span>
                                    </div>
                                </div>

                                <ExportSaveButtons/>

                            </div>
                            <div className={"display-flex"}>
                                <div className={"position-container left container-positions"}>
                                    <div className={"position-component"}>
                                        <h3>
                                            <i className="fa-solid fa-house" style={{marginRight: "10px"}}></i>
                                            Informations du client
                                        </h3>

                                        <div className={"display-flex justify-content-space-between"}>

                                            <div>
                                            <span className={'icon-info-client'}>
                                                <i className="fa-solid fa-house" style={{marginRight: "10px"}}/>
                                            </span>
                                                <span>{position.name_1}</span>
                                                <p style={{marginLeft: "40px"}}>{position.name_2}</p>
                                                <p style={{marginLeft: "40px"}}>{position.address}</p>
                                                <p style={{marginLeft: "40px"}}>{position.postcode} {position.city}</p>
                                                <p>
                                                    <a style={{textDecoration: "none"}}
                                                       href={"tel:" + position.phone_fixed}>
                                                    <span className={'icon-info-client'}>
                                                        <i className="fa-solid fa-phone" style={{marginRight: "10px"}}/>
                                                    </span>
                                                        {position.phone_fixed}
                                                    </a>
                                                </p>
                                                <p>
                                                    <a style={{textDecoration: "none"}}
                                                       href={"mailto:" + position.mail}>
                                                    <span className={'icon-info-client'}>
                                                        <i className="fa-solid fa-envelope"
                                                           style={{marginRight: "10px"}}/>
                                                    </span>
                                                        <span>{position.mail}</span>
                                                    </a>
                                                </p>
                                                <p>
                                                    <a style={{textDecoration: "none"}} target={"_blank"}
                                                       href={"https://office.bexio.com/index.php/kb_order/show/id/" + position.order_id}
                                                       rel="noreferrer">

                                                    <span className={'icon-info-client'}>
                                                        <i className="fa-solid fa-link" style={{marginRight: "10px"}}/>
                                                    </span>
                                                        <span>
                                                    Contrat Bexio
                                                </span>
                                                    </a>
                                                </p>
                                                <p>
                                            <span className={'icon-info-client'}>
                                            <i className="fa-solid fa-industry"/>                                            </span>
                                                    <span>{position.branch_name}</span>
                                                </p>
                                                {position.url !== null && <p>
                                                    <a style={{textDecoration: "none"}} target={"_blank"}
                                                       href={position.url}
                                                       rel="noreferrer">

                                            <span className={'icon-info-client'}>
                                            <i className="fa-solid fa-link" style={{marginRight: "10px"}}/>
                                            </span>
                                                        <span>

                                            {position.url}
                                            </span>
                                                    </a>
                                                </p>

                                                }

                                                <p>
                                        <span style={{textDecoration: "none"}}>
                                            <span className={'icon-info-client'}>
                                            <i className="fa-solid fa-user"
                                               style={{
                                                   marginRight: "10px"
                                               }}/>
                                            </span>
                                            <span>{position.contact_firstname} {position.contact_lastname}</span>
                                        </span>
                                                </p>

                                            </div>

                                            <div>
                                                <span className={"display-flex align-items-center mb-10"}>
                                                    <NavLink to={`/client/${position.contact_id}`}
                                                             className={'text-decoration-none'}>
                                                        <span
                                                            className={'client-button display-flex justify-content-center align-items-center mr-10 cursor-pointer'}>
                                                            <i className="fa-solid fa-eye mr-10"/>
                                                            <span>Voir la fiche client</span>
                                                         </span>
                                                    </NavLink>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"position-component"}>
                                        <h3 className={"title-positions-menus"} onClick={toggleOpenInfo}>
                                            <i className="fa-solid fa-circle-info" style={{marginRight: "10px"}}></i>
                                            Informations générales
                                            <i
                                                className={`fa-solid fa-chevron-down arrow-icon ${isOpenInfo ? 'rotate' : ''}`}
                                                style={{marginLeft: "10px"}}
                                            ></i>

                                        </h3>
                                        {isOpenInfo && (<Fragment>
                        <textarea
                            className="position-note-textarea"
                            id="position_note"
                            style={{width: "100%", marginTop: 20}}
                            onChange={(e) => handleCommentSection(e.target.value)}
                            value={positionNote}
                        />
                                        </Fragment>)}
                                    </div>

                                    <div className={"position-component"}>
                                        <h3 className={"title-positions-menus"} onClick={toggleOpenPositions}>
                                            <i className="fa-solid fa-list" style={{marginRight: "10px"}}></i>
                                            Autres projets en cours
                                            <i
                                                className={`fa-solid fa-chevron-down arrow-icon ${isOpenOpenPositions ? 'rotate' : ''}`}
                                                style={{marginLeft: "10px"}}
                                            ></i>
                                        </h3>
                                        {isOpenOpenPositions && <OpenPositions id={position}/>}
                                    </div>

                                    <div className={"position-component"}>
                                        <h3 className={"title-positions-menus"} onClick={toggleClosedPositions}>
                                            <i className="fa-solid fa-check" style={{marginRight: "10px"}}></i>
                                            Autres projets terminés
                                            <i
                                                className={`fa-solid fa-chevron-down arrow-icon ${isOpenClosedPositions ? 'rotate' : ''}`}
                                                style={{marginLeft: "10px"}}
                                            ></i>
                                        </h3>
                                        {isOpenClosedPositions && <ClosedPositions id={position}/>}
                                    </div>

                                </div>

                                <div className={"position-container right"}>
                                    <div className={"position-component"}>

                                        <div className="toggle-button mb-10"
                                             onClick={() => toggleIsOpenProgressTask()}>Tâches en cours
                                            ({inProgressTasks.length}/{inProgressTasks.length + finishedTasks.length})
                                        </div>

                                        {(isOpenProgressTask || position.progress < 100) && <Tasks
                                            searchBar={false}
                                            title={null}
                                            tasks={inProgressTasks}
                                            displayAdminBar={true}
                                            displayCheckbox={true}
                                            headers={['Progression', 'Nom de la tâche', 'Durée estimée', 'Durée', 'Date de réalisation', 'Terminé le', 'Personne en charge', 'Action']}
                                            headersData={['progress', 'title_open_position', 'estimated_duration', 'time_spent', 'finish_date_custom', 'finish_date', 'username', 'delete_task']}
                                        />}

                                        <div className="toggle-button"
                                             onClick={() => toggleIsOpenFinishedTask()}>Tâches terminées
                                            ({finishedTasks.length}/{inProgressTasks.length + finishedTasks.length})
                                        </div>

                                        {(isOpenFinishedTask || position.progress === 100) && (<Tasks
                                            searchBar={false}
                                            title={null}
                                            tasks={finishedTasks}
                                            displayCheckbox
                                            headers={['Progression', 'Nom de la tâche', 'Durée estimée', 'Terminé le', 'Personne en charge', 'Durée']}
                                            headersData={['progress', 'title_open_position', 'estimated_duration', 'finished_at', 'username', 'time_spent']}
                                        />)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>

        ))}
    </Fragment>);
};

export default PositionDetail;
