import React, {useEffect} from 'react';
import WIP from "../components/WIP";

const PatchXgrid = () => {
    useEffect(() => {
        document.title = 'X | Patches XGrid'
    }, []);
    return (
        <div>
            <h2>Patch XGrid</h2>
            <WIP/>
        </div>
    );
};

export default PatchXgrid;