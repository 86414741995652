import React, {Fragment, useEffect, useState} from "react";
import '../assets/css/Account.css';
import axios from "axios";
import {API_BASE_URL} from "../config";

const Account = () => {
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [isNewPasswordFocused, setIsNewPasswordFocused] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [message, setMessage] = useState('');
    const [updatedPasswordDate, setUpdatedPasswordDate] = useState('');
    const [daysRemaining, setDaysRemaining] = useState(0);
    const user_mail = localStorage.user_mail;

    useEffect(() => {
        document.title = "X | Mon compte"
        axios.post(`${API_BASE_URL}/get_user_infos`, {user: user_mail})
            .then(r => {
                const date = new Date(r.data[0].updated_date);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                const year = date.getFullYear();

                const formattedDate = `${day}/${month}/${year}`;
                setUpdatedPasswordDate(formattedDate);

                // Calculate days remaining
                const expirationDate = new Date(date);
                expirationDate.setMonth(expirationDate.getMonth() + 6);
                const today = new Date();
                const timeDiff = expirationDate - today;
                const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                setDaysRemaining(daysLeft);

            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const checkPasswords = (newPasswordVal) => {
        newPassword !== newPasswordVal ? setMessage('Les mots de passe ne correspondent pas.') : setMessage('')
    }

    const updatePassword = () => {
        if (newPassword !== newPasswordConfirm) {
            setMessage('Les deux mots de passe ne correspondent pas.');
            return;
        }

        axios.post(`${API_BASE_URL}/update_user_password`, {
            id: localStorage.user_id,
            oldPassword,
            newPassword
        })
            .then(res => {
                console.log(res.data);
                setMessage(res.data.message);
                setOldPassword('');
                setNewPassword('');
                setNewPasswordConfirm('');
            })
            .catch(err => {
                setMessage(err.response.data.error);
            });
    };

    return (
        <Fragment>
            <div>
                <div className="account-container">
                    <h2 className="title">Mon compte</h2>
                    <div className={'form-account display-grid'}>
                        <div className={"display-grid"} style={{padding: "50px", textAlign: "left"}}>
                            <span className={'text-primary mb-20'}>Modifiez les préférences de votre compte</span>
                            <hr className={'mb-10'}/>
                            <div className={"display-flex mt-10 mb-20"}>
                                <div style={{width: "25vw"}}>
                                    <span className={'label-account'}>Mot de passe</span><br/>
                                    <span className={`small-description-account ${isPasswordFocused ? 'show' : ''}`}>Votre mot de passe actuel</span>
                                </div>
                                <input
                                    type={"password"}
                                    onFocus={() => setIsPasswordFocused(true)}
                                    onBlur={() => setIsPasswordFocused(false)}
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    className={'input-account'}
                                />
                            </div>
                            <div className={"display-flex mb-20"}>
                                <div style={{width: "25vw"}}>
                                    <span className={'label-account'}>Nouveau mot de passe</span><br/>
                                    <span className={`small-description-account ${isNewPasswordFocused ? 'show' : ''}`}>Choisissez un mot de passe fort</span>
                                </div>
                                <input
                                    type={"password"}
                                    onFocus={() => setIsNewPasswordFocused(true)}
                                    onBlur={() => setIsNewPasswordFocused(false)}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className={'input-account'}
                                />
                            </div>
                            <div className={"display-flex mb-20 align-items-baseline"}>
                                <div style={{width: "25vw"}}>
                                    <span className={'label-account'}>Répéter le nouveau mot de passe</span>
                                </div>
                                <input
                                    type={"password"}
                                    value={newPasswordConfirm}
                                    onChange={(e) => {
                                        setNewPasswordConfirm(e.target.value);
                                        checkPasswords(e.target.value);
                                    }}
                                    className={'input-account'}
                                />
                                <i className={'fa regular-fas-eye'} />
                            </div>
                            <div className={"display-flex mb-20 spans-account gap-10 mt-20"}>
                                <span>
                                    {daysRemaining > 0 && daysRemaining <= 7
                                        ? <span style={{color: "red"}}>Il vous reste ${daysRemaining} jour(s) pour changer de mot de passe.</span>
                                        : <span style={{color: "var(--primary)"}}>Votre mot de passe a été changé le {updatedPasswordDate}.</span>}
                                </span>
                                <span className={'btn-submit-account'} onClick={updatePassword}>Mettre à jour</span>
                            </div>
                            {message && <div className="message">{message}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Account;
