import { useState, useEffect } from 'react';
function TimeInput({ item, handleTimeSpent }) {
    const [tempValue, setTempValue] = useState(item.time_spent);

    useEffect(() => {
        setTempValue(item.time_spent);
    }, [item.time_spent]);

    const handleChange = (e) => {
        setTempValue(e.target.value); // Update local state during input
    };

    const handleBlur = () => {
        handleTimeSpent(tempValue); // Call the passed function with the updated value
    };

    return (
        <input
            className="input-time input"
            style={{ padding: '10px 5px', height: '40px' }}
            type="time"
            value={tempValue} // Use local state
            onChange={handleChange}
            onBlur={handleBlur} // Save the value on blur
        />
    );
}


export default TimeInput;
