import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import {API_BASE_URL} from "../config";

const OpenPositions = (id) => {

    const [openPositions, setOpenPositions] = useState([]);

    const user_id = id.id.contact_id;
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_open_positions/`, {user_id})
            .then(res => {
                setOpenPositions(res.data)
            })
    }, [id, user_id]);

    return (
        <div>

            <div className={"positions-list"} style={{width: "100%", marginTop: 20}}
            >
                {
                    openPositions.map((el, index) => (

                        <Link to={'/positions/' + el.id} key={index} style={{textDecoration: "none"}} >
                            <div className={"positions-list-item"} style={{padding: "10px", marginBottom: "5px", borderRadius: "5px"}}>
                                <i className="fa-solid fa-link" style={{marginRight: "10px"}}></i>
                                <span>{el.id} </span>

                                <span style={{width: "100%", height: "auto"}}
                                   dangerouslySetInnerHTML={{__html: el.title}}/>

                            </div>
                        </Link>

                        )
                    )
                }
            </div>
        </div>
    );
};

export default OpenPositions;