import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import Chart from "../components/Chart";
import SearchBar from "../components/SearchBar";
import Table from "../components/Table";
import { API_BASE_URL } from "../config";

const SitesPublies = () => {
    const [prod, setProd] = useState(null);
    const [published, setPublished] = useState(null);
    const [sortBy, setSortBy] = useState({ column: null, ascending: true });

    const columnMapping = {
        "Publié le": "creation_date",
        'Site URL': 'url',
        "Admin URL": "url",
        "Version XGrid": "version",
        "Serveur": "server",
        // Add more mappings as needed
    };

    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({ column: actualColumn, ascending });
    };

    useEffect(() => {
        document.title = 'X | Sites visu'
    }, []);
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_visus`)
            .then(response => {
                console.log(response.data)
                const filteredData = response.data.filter(item =>
                    !item.url.includes('solution-digitale') &&
                    !item.url.includes('xgrid') &&
                    !item.url.includes('test') &&
                    !item.url.includes('00') &&
                    !item.url.includes('01')
                );                const sortedData = filteredData.sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date));

                // Format the creationDate field to DD/MM/YYYY
                const formattedData = sortedData.map(item => ({
                    ...item,
                    creation_date: new Date(item.creation_date).toLocaleDateString('fr-FR')
                }));

                console.log(sortedData)
                setProd(formattedData);
                setPublished(formattedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const publishedArray = Array.isArray(published) ? published : [];
    const sortedArray = [...publishedArray].sort((a, b) => {
        if (sortBy.column === 'creationDate') {
            return sortBy.ascending ? new Date(a.creationDate) - new Date(b.creationDate) : new Date(b.creationDate) - new Date(a.creationDate);
        } else if (sortBy.column === 'version') {
            const versionA = a.version === null ? '0' : a.version;
            const versionB = b.version === null ? '0' : b.version;
            return sortBy.ascending ? versionA.localeCompare(versionB) : versionB.localeCompare(versionA);
        } else if (sortBy.column === 'url') {
            return sortBy.ascending ? a.url.localeCompare(b.url) : b.url.localeCompare(a.url);
        } else if (sortBy.column === 'server') {
            return sortBy.ascending ? a.server.localeCompare(b.server) : b.server.localeCompare(a.server);
        }
        return 0;
    });

    return (
        <Fragment>
            <div>
                <h2>Liste des sites en visu</h2>
                <Chart data={prod} type={'area-spline'} dateField={'creation_date'} />

                <SearchBar totalPositions={prod} setPublished={setPublished} totalPositionsLength={publishedArray}
                           searchLabel={'sites trouvés'}
                />
                <Table
                    typeOfPosition={"finished"}
                    data={sortedArray}
                    handleSort={handleSort}
                    sortBy={sortBy}
                    hidePagination={true}
                    headersData={['creation_date', 'visu_url', 'visu_url_admin', 'version']}
                    headers={["Publié le", "Site URL", "Admin URL", "Version XGrid"]}
                />
            </div>
        </Fragment>
    );
};

export default SitesPublies;
