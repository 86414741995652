import React, { useEffect, useRef, Fragment } from 'react';
import c3 from 'c3';
import 'c3/c3.css';

function Chart({ data, type, dateField }) {
    const chartRef = useRef(null);

    useEffect(() => {
        if (!data || data.length === 0) {
            return;
        }

        const aggregatedData = {};


        if (dateField === 'timestamp') {
            data.forEach(item => {
                if (!item['timestamp']) return;

                const date = new Date(parseInt(item['timestamp'], 10));
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1

                const key = `${year}-${month}`;

                if (!aggregatedData[year]) {
                    aggregatedData[year] = {};
                }
                if (!aggregatedData[year][month]) {
                    aggregatedData[year][month] = 1;
                } else {
                    aggregatedData[year][month]++;
                }
            });
        } else {
            data.forEach(item => {
                if (!item[dateField]) return;

                const parts = item[dateField].split('/');
                const year = parts[2];
                const month = parts[1].padStart(2, '0'); // Pad single-digit months with leading zero

                if (!aggregatedData[year]) {
                    aggregatedData[year] = {};
                }
                if (!aggregatedData[year][month]) {
                    aggregatedData[year][month] = 1;
                } else {
                    aggregatedData[year][month]++;
                }
            });
        }

        const orderedMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

        const columns = [];
        Object.entries(aggregatedData).forEach(([year, monthCounts]) => {
            const yearData = [year];
            orderedMonths.forEach((month, index) => {
                const monthKey = (index + 1).toString().padStart(2, '0'); // Get the numeric month key
                const count = monthCounts[monthKey] || 0; // Get the count for the month
                yearData.push(count);
            });
            columns.push(yearData);
        });

        const chart = c3.generate({
            bindto: chartRef.current,
            data: {
                columns: columns,
                type: type,
                colors: {
                    '2021': '#8be781',
                    '2022': '#47d8fa',
                    '2023': 'rgba(250,28,28,0.15)',
                    '2024': '#1dbab0'
                },
            },
            axis: {
                x: {
                    type: 'category',
                    categories: orderedMonths,
                },
                y: {
                    tick: {
                        values: [0, 5, 10, 15, 20, 25],
                        format: function (d) {
                            return parseInt(d); // Format the tick values as integers
                        }
                    },
                    label: {
                        position: 'outer-middle'
                    }
                },
            }
        });

        // Cleanup on component unmount
        return () => {
            chart.destroy();
        };
    }, [data, type, dateField]);

    return (
        <Fragment>
            <div className={'display-flex mb-20'} style={{ display: "flex", marginBottom: "20px", minHeight: "360px" }}>
                <div className={'chart-container flex-1'}>
                    <div ref={chartRef} />
                </div>
            </div>
        </Fragment>
    );
}

export default Chart;
