import React from 'react';
import {NavLink} from "react-router-dom";

const ModalAccount = () => {
    return (
        <div>

            <div className={"modal-account-container"}>
                <ul className={"gap-10"}>
                    <NavLink to="account" activeClassName="active" className={"text-decoration-none"}>
                        <i className="fa-regular fa-user color-third "/><strong className={"ml-10"}>Mon compte</strong></NavLink>

                    <span className={"logout-container"}>
                        <NavLink to="logout" activeClassName="active" className={"text-decoration-none"} >
                        <i className="fa-solid fa-arrow-right-from-bracket color-white"/><span className={"ml-10 color-white"}>Se déconnecter</span>
                    </NavLink>
                    </span>

                </ul>
            </div>
            
        </div>
    );
};

export default ModalAccount;