import React, {useState, useEffect} from 'react';
import axios from 'axios';
import SearchBar from "../components/SearchBar";
import Table from "../components/Table";
import {API_BASE_URL} from "../config"; // Update the path to config.js as needed

const PositionsDeleted = () => {
    const [published, setPublished] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [sortBy, setSortBy] = useState({column: null, ascending: true});

    const columnMapping = {
        "Progression": "progression",
        'N° Bexio commande': 'order_id',
        "Titre de la commande": "title",
        "Client": "client",
        "Supprimé le": "deletion_date",
        // Add more mappings as needed
    };

    console.log(totalPositions)

    useEffect(() => {
        document.title = 'X | Projets archivés'
    }, []);
    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({column: actualColumn, ascending});
    };

    const handleAction = (action, id) => {

        // On edit/voit la position
        if (action === 'edit') {

            const url = `/positions/${id}`;
            window.open(url);
        }

    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_deleted_positions`, {state: 'deleted'})
            .then(res => {

                if (res.data.status === 'ok') {
                    setPublished(res.data.datas);
                    setTotalPositions(res.data.datas)
                } else {
                    console.error('Erreur :', res.data.error);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // Ensure published is always an array
    const publishedArray = Array.isArray(published) ? published : [];

    const customSort = (a, b) => {
        const safeCompare = (fieldA, fieldB) => {
            const valA = fieldA || ''; // Default to empty string
            const valB = fieldB || '';
            return valA.localeCompare(valB);
        };

        const parseDate = (dateString) => {
            // Handle null, undefined, or placeholder dates
            if (!dateString || dateString === "0000-00-00 00:00:00") return null;
            return new Date(dateString); // Convert to Date object
        };

        switch (sortBy.column) {
            case 'progression':
                return sortBy.ascending ? a.progress - b.progress : b.progress - a.progress;
            case 'order_id':
                return sortBy.ascending ? a.order_id - b.order_id : b.order_id - a.order_id;
            case 'title':
                return sortBy.ascending ? safeCompare(a.title, b.title) : safeCompare(b.title, a.title);
            case 'client':
                return sortBy.ascending ? safeCompare(a.name_1, b.name_1) : safeCompare(b.name_1, a.name_1);
            case 'deletion_date': {
                const dateA = parseDate(a.deletion_date);
                const dateB = parseDate(b.deletion_date);
                if (dateA && dateB) return sortBy.ascending ? dateA - dateB : dateB - dateA;
                if (!dateA) return sortBy.ascending ? 1 : -1;
                if (!dateB) return sortBy.ascending ? -1 : 1;
                return 0;
            }
            default:
                return 0;
        }
    };

    const sortedArray = [...publishedArray].sort(customSort);

    return (
        <div>
            <SearchBar totalPositions={totalPositions} setPublished={setPublished}
                       totalPositionsLength={publishedArray} positionLabel={'Projets supprimés'}
                       searchLabel={'projets supprimés trouvés'}/>
            <Table
                data={sortedArray}
                handleSort={handleSort}
                sortBy={sortBy}
                showActions={true}
                handleAction={handleAction}
                headers={["N° Bexio commande", "Titre de la commande", "Client", "Supprimé le", "Actions"]}
                headersData={['order_id', 'title', 'name_1', 'deletion_date']}
            />
        </div>

    );
};

export default PositionsDeleted;
