import React, {useEffect, useState, Fragment} from 'react';
import axios from 'axios';
import Chart from "../components/Chart";
import SearchBar from "../components/SearchBar";
import Table from "../components/Table";
import {API_BASE_URL} from "../config";

const SitesPublies = () => {
    const [prod, setProd] = useState(null); // Initialize prod with null or initial value
    const [published, setPublished] = useState(null); // Initialize prod with null or initial value
    const [sortBy, setSortBy] = useState({column: null, ascending: true});

    const columnMapping = {
        "Publié le": "date",
        'Site URL': "url",
        "Admin URL": "url",
        "Version XGrid": "version",
        "Serveur": "server",
        "Secteur": "branch_names",
        "Console": "sended"
        // Add more mappings as needed
    };

    useEffect(() => {
        document.title = 'X | Sites publiés'
    }, []);
    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({column: actualColumn, ascending});
    };

    const handleAction = (action, id) => {
        // On edit la position
        if (action === 'edit') {

            const url = `/positions/${id}`;
            window.open(url);
        }

        if (action === 'delete') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette position ?");

            if (res) {
                axios.post(`${API_BASE_URL}/delete_position`, {id})
                    .then(response => {
                        console.log(response.data); // Handle server response if needed
                    })
                    .catch(error => {
                        console.error('Error deleting position:', error);
                    })
                    .finally(() => {
                        //setUpdatePositions(updatePositions + 1)
                    });
            }
        }
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_published`, {
            responseType: 'json', // Specify response type as JSON
            cache: 'no-cache', // Prevent caching in Axios
        })
            .then(response => {
                console.log(response);

                // Filter if needed
                const filteredData = response.data.filter(item =>
                    !item.url.includes('solution-digitale') &&
                    !item.url.includes('xgrid') &&
                    !item.url.includes('00') &&
                    !item.url.includes('01')
                );

                // Sort the data by date
                const sortedData = filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

                // Format the creationDate field to DD/MM/YYYY
                const formattedData = sortedData.map(item => ({
                    ...item,
                    date: new Date(item.date).toLocaleDateString('ch-FR')
                }));

                console.log(sortedData);
                setProd(formattedData);
                setPublished(formattedData);

                // If there are formattedData items, loop through and make API calls
                if (formattedData.length > 0) {
                    const requests = formattedData.map(item => {
                        const bexio_id = item.bexio; // Extracting bexio_id from each item

                        return axios.post(`${API_BASE_URL}/get_customer_via_bexio_id`, { bexio_id: bexio_id })
                            .then(res => {
                                // Merge the customer data with the corresponding item
                                console.log(res)
                                return {
                                    ...item, // original published data
                                    customer: res.data // merge with customer data
                                };
                            })
                            .catch(error => {
                                console.error(`Error fetching customer data for ${bexio_id}:`, error);
                                return { ...item, customer: null }; // In case of error, still keep the original item
                            });
                    });

                    // Wait for all requests to complete
                    Promise.all(requests)
                        .then(allMergedData => {
                            // Handle all merged data together if needed
                            console.log('All Merged Data:', allMergedData);
                            setPublished(allMergedData); // Update the state with merged data
                        });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []); // Empty dependency array to run once when component mounts




    const publishedArray = Array.isArray(published) ? published : [];
    const sortedArray = [...publishedArray].sort((a, b) => {
        if (sortBy.column === 'date') {
            return sortBy.ascending ? a.timestamp - b.timestamp : b.timestamp - a.timestamp;
        } else if (sortBy.column === 'version') {
            // check si version est null, à ce moment on lui dit que ça vaut 0 (pour l'ordre du tri)
            const versionA = a.version === null ? '0' : a.version;
            const versionB = b.version === null ? '0' : b.version;
            return sortBy.ascending ? versionA.localeCompare(versionB) : versionB.localeCompare(versionA);
        } else if (sortBy.column === 'url') {
            // Assuming title is a string, you can use localeCompare for alphabetical sorting
            return sortBy.ascending ? a.url.localeCompare(b.url) : b.url.localeCompare(a.url);
        } else if (sortBy.column === 'name_1') {
            console.log('ok name')
            // Assuming title is a string, you can use localeCompare for alphabetical sorting
            return sortBy.ascending ? a.name_1.localeCompare(b.name_1) : b.name_1.localeCompare(a.name_1);
        } else if (sortBy.column === 'server') {
            // Assuming client is a string, you can use localeCompare for alphabetical sorting
            return sortBy.ascending ? a.server.localeCompare(b.server) : b.server.localeCompare(a.server);
        } else if (sortBy.column === 'branch_names') {
            return sortBy.ascending
                ? (a.branch_names ?? '').localeCompare(b.branch_names ?? '')
                : (b.branch_names ?? '').localeCompare(a.branch_names ?? '');
        } else if (sortBy.column === 'sended') {
            return sortBy.ascending
                ? (a.sended ?? '').localeCompare(b.sended ?? '')
                : (b.sended ?? '').localeCompare(a.sended ?? '');
        }
        // Add similar cases for other columns if needed
        return 0; // Default case
    });

    return (
        <Fragment>
            <div>
                <h2>Liste des sites publiés</h2>
                <Chart data={prod} type={'area-spline'} dateField={'timestamp'}/>

                <SearchBar totalPositions={prod} setPublished={setPublished} totalPositionsLength={publishedArray}
                           searchLabel={'sites trouvés'}
                />
                <Table
                    typeOfPosition={"finished"}
                    data={sortedArray}
                    handleSort={handleSort}
                    sortBy={sortBy}
                    hidePagination={true}
                    handleAction={handleAction}
                    headersData={['date', 'url', 'contact_id', 'branch_names', 'url_admin', 'version', 'server', 'sended']}
                    headers={["Publié le", "Site URL",  'Client', 'Secteur',"Admin URL", "Version XGrid", "Serveur", "Console"]}
                />
            </div>
        </Fragment>);
};

export default SitesPublies;
