import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchBar from "../components/SearchBar";
import Table from "../components/Table";
import { API_BASE_URL } from "../config";

const PositionsFinished = () => {
    const [published, setPublished] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatePositions, setUpdatePositions] = useState(0);
    const [sortBy, setSortBy] = useState({ column: null, ascending: true });

    console.log(sortBy)
    useEffect(() => {
        document.title = 'X | Projets terminés'
    }, []);
    const columnMapping = {
        "Progression": "progression",
        'N° Bexio commande': 'order_id',
        "Titre de la commande": "title",
        "Client": "client",
        "Date de fin du projet": "end_date",
        "Date de création du projet": "order_start_date",
        // Add more mappings as needed
    };

    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({ column: actualColumn, ascending });
    };

    const handleAction = (action, id) => {
        // On edit la position
        if (action === 'edit') {
            const url = `/positions/${id}`;
            window.open(url);
        }

        if (action === 'delete') {
            const res = window.confirm("Voulez-vous vraiment supprimer cette position ?");

            if (res) {
                setLoading(true);
                axios.post(`${API_BASE_URL}/delete_position`, { id })
                    .then(response => {
                        if (response.data.status === 'ok') {
                            // Directly update the published state to remove the deleted position
                            setPublished(prevPublished => prevPublished.filter(position => position.id !== id));
                            setTotalPositions(prevTotalPositions => prevTotalPositions.filter(position => position.id !== id));
                        } else {
                            console.error('Error deleting position:', response.data.error);
                        }
                    })
                    .catch(error => {
                        console.error('Error deleting position:', error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}/get_finished_positions`)
            .then(res => {
                if (res.data.status === 'ok') {
                    setPublished(res.data.datas);
                    setTotalPositions(res.data.datas);
                } else {
                    console.error('Erreur :', res.data.error);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });

    }, [updatePositions]);

    // Ensure published is always an array
    const publishedArray = Array.isArray(published) ? published : [];

    const sortedArray = [...publishedArray].sort((a, b) => {
        if (sortBy.column === 'progression') {
            return sortBy.ascending ? a.progress - b.progress : b.progress - a.progress;
        } else if (sortBy.column === 'order_id') {
            return sortBy.ascending ? a.order_id - b.order_id : b.order_id - a.order_id;
        } else if (sortBy.column === 'order_start_date') {
            // Convert the date strings to Date objects and compare their timestamps
            return sortBy.ascending ? new Date(a.order_start_date) - new Date(b.order_start_date) : new Date(b.order_start_date) - new Date(a.order_start_date);
        } else if (sortBy.column === 'end_date') {
            // Convert the date strings to Date objects and compare their timestamps
            return sortBy.ascending ? new Date(a.end_date) - new Date(b.end_date) : new Date(b.end_date) - new Date(a.end_date);
        } else if (sortBy.column === 'title') {
            return sortBy.ascending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        } else if (sortBy.column === 'client') {
            return sortBy.ascending ? a.name_1.localeCompare(b.name_1) : b.name_1.localeCompare(a.name_1);
        }
        return 0; // Default case
    });


    return (
        <div>
            <SearchBar
                totalPositions={totalPositions}
                setPublished={setPublished}
                totalPositionsLength={publishedArray}
                positionLabel={'Projets terminés'}
                searchLabel={'projets trouvés'}
            />
            <Table
                data={sortedArray}
                handleSort={handleSort}
                sortBy={sortBy}
                showActions={true}
                handleAction={handleAction}
                headersData={['progress', 'order_id', 'title', 'name_1', 'end_date', 'order_start_date']}
                headers={["Progression", "N° Bexio commande", "Titre de la commande", "Client", "Date de fin du projet", "Date de création du projet", "Actions"]}
            />
        </div>
    );
};

export default PositionsFinished