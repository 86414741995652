import React, { useState } from 'react';
const SearchDropdown = ({ data, onSelect, placeholder = "Rechercher..." }) => {
    const [searchTerm, setSearchTerm] = useState(''); // Search term input by the user
    const [filteredData, setFilteredData] = useState(data); // Filtered data for dropdown

    // Handle search input change
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        if (value) {
            const filtered = data.filter(item =>
                item.label.toLowerCase().includes(value.toLowerCase())  // Search by 'label'
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(data); // Reset to full list if search term is empty
        }
    };

    const clearSearch = () => {
        setSearchTerm('');
    };

    // Handle item selection
    const handleSelectItem = (item) => {
        console.log("Selected item:", item); // Log the selected item for debugging
        setSearchTerm(item.label);  // Set the input to the label of the selected item
        setFilteredData([]);  // Clear the dropdown
        onSelect(item);  // Pass the full item object to the parent component
    };

    return (
        <div className="search-dropdown">
            <div className="" style={{ position: 'relative' }}>
                <input
                    className={"input"}
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder={placeholder}
                    autoComplete="off"
                />
                {searchTerm && (
                    <button
                        onClick={clearSearch}
                        style={{
                            position: 'absolute',
                            right: '0px',
                            top: '20px',
                            transform: 'translateY(-50%)',
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '24px',
                        }}
                    >
                        &times;
                    </button>
                )}

                {searchTerm && filteredData.length > 0 && (
                    <ul className="dropdown-search">
                        {filteredData.map((item) => (
                            <li key={item.id} onClick={() => handleSelectItem(item)}>
                                {item.label} {/* Display label which includes intern_code and intern_name */}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};


export default SearchDropdown;
