import React, {useState, useEffect, Fragment} from 'react';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import Board from 'react-trello';
import {API_BASE_URL} from '../config';
import '../assets/css/Trello.css';
import ModalPlanification from "../components/Trello/components/ModalPlanification";
import CustomAddCardLink from '../components/Trello/components/CustomAddCardLink';
import Modal from '../components/Trello/components/Modal'; // Assume you have a Modal component

import { createTranslate } from 'react-trello'

const components = {
    AddCardLink: CustomAddCardLink,
};
const Trello = () => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newLaneTitle] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalInfo, setModalInfo] = useState({
        title: '',
        type: '',
        name: '',
        description: '',
        color: '',
        department: ''
    });
    const [departments, setDepartments] = useState([]);
    const [newDepartment, setNewDepartment] = useState('');

    useEffect(() => {
        document.title = 'X | Trello'
    }, []);
    const handleCardClick = (cardId) => {
        console.log('ok')
        setSelectedCard(cardId);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCard(null);
    };
    useEffect(() => {
        fetchLanesAndUsers();
        fetchDepartments();
    }, []);


    const TEXTS = {
        "Add another lane": "Nouvelle colonne",
        "Click to add card": "Nouvelle carte",
        "Delete lane": "Supprimer la colonne",
        "Lane actions": "Lane actions",
        "button": {
            "Add lane": "Ajouter",
            "Add card": "Ajouter",
            "Cancel": "Annuler"
        },
        "placeholder": {
            "title": "Titre",
            "description": "Description",
            "label": "label"
        }
    }

    const fetchLanesAndUsers = () => {
        Promise.all([
            axios.post(`${API_BASE_URL}/get_trello_columns`),
            axios.post(`${API_BASE_URL}/get_trello_cards`)
        ])
            .then(([lanesRes, tasksRes]) => {
                const lanesData = lanesRes.data;
                const tasksData = tasksRes.data;

                if (lanesData.status === 'ok' && Array.isArray(lanesData.data)) {
                    const lanes = lanesData.data;
                    const tasks = tasksData.data;

                    const lanesWithCards = lanes.map(lane => {
                        const laneId = lane.id.toString();
                        const filteredTasks = tasks.filter(task => task.lane_id.toString() === laneId);

                        return {
                            ...lane,
                            style: { width: 280, backgroundColor: lane.color || 'var(--primary)' }, // Apply background color
                            cards: filteredTasks.map(task => ({
                                id: task.id,
                                title: task.title,
                                description: task.description,
                                color: task.color,
                                lane_id: task.lane_id
                            }))
                        };
                    });

                    // Set data with lanes and ensure styling is applied
                    setData({ lanes: lanesWithCards });
                    setLoading(false);
                } else {
                    throw new Error('Lanes data is not an array or status is not ok');
                }
            })
            .catch(error => {
                setError('Failed to load data: ' + error.message);
                setLoading(false);
            });
    };


    const fetchDepartments = () => {
        axios.post(`${API_BASE_URL}/get_trello_department`)
            .then((res) => {
                if (res.data.status === 'ok' && Array.isArray(res.data.data)) {
                    setDepartments(res.data.data);
                } else {
                    throw new Error('Departments data is not an array or status is not ok');
                }
            })
            .catch(error => {
                setError('Failed to load departments: ' + error.message);
            });
    };

    const handleCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
        axios.post(`${API_BASE_URL}/save_to_trello`, {
            action: 'move_card',
            card_id: cardId,
            from_lane_id: fromLaneId,
            to_lane_id: toLaneId,
            position: index
        })
            .then(() => {
                fetchLanesAndUsers();
            })
            .catch(error => {
                setError('Failed to move card: ' + error.message);
            });
    };

    const handleAddLane = (e) => {
        if (newLaneTitle.trim() !== '') {
            axios.post(`${API_BASE_URL}/add_lane`, {title: newLaneTitle})
                .then(() => {
                    fetchLanesAndUsers();
                })
                .catch(error => {
                    setError('Failed to add lane: ' + error.message);
                });
        } else {
            setError('Please enter a lane title');
        }
    };

    const openModal = (title, type) => {
        setModalInfo({title: title, description: '', type, name: '', color: '', department: ''});
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setModalInfo({...modalInfo, [name]: value});
    };

    const handleSave = () => {
        const {type, name, description, color, department} = modalInfo;
        axios.post(`${API_BASE_URL}/save_to_trello`, {
            action: type,
            title: name,
            description: description,
            color: color,
            department: department
        })
            .then(() => {
                fetchLanesAndUsers();
                setModalVisible(false);
            })
            .catch(error => {
                setError('Failed to save data: ' + error.message);
            });
    };

    const editCard = (action, data) => {
        console.log(action, data)
        axios.post(`${API_BASE_URL}/save_to_trello`, {
            action: action,
            data: data
        })
            .then(() => {
                fetchLanesAndUsers();
            })
            .catch(error => {
                setError('Failed to save data: ' + error.message);
            });
    }

    const handleAddDepartment = () => {
        if (newDepartment.trim() !== '') {
            axios.post(`${API_BASE_URL}/add_department`, {name: newDepartment})
                .then(() => {
                    fetchDepartments();
                    setNewDepartment('');
                })
                .catch(error => {
                    setError('Failed to add department: ' + error.message);
                });
        } else {
            setError('Please enter a department name');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    };

    return (
        <div style={{backgroundColor: "#084B5A", height: "calc(100vh - 40px)"}}>
            <Fragment>
                <h2 className={"color-white"}>Planification</h2>
                {loading && <p>Loading...</p>}
                {!isEmpty(data) && (
                    <div style={{height: "calc(100vh - 100px!important)"}}>
                        <Board
                            components={components}
                            t={createTranslate(TEXTS)}
                            data={data}
                            draggable
                            onCardClick={(cardId, metadata, laneId) => handleCardClick(cardId)}
                            onCardUpdate={(cardId, data) => editCard('update_card', data)}
                            handleDragEnd={(cardId, sourceLaneId, targetLaneId, position, cardDetails) =>
                                handleCardMoveAcrossLanes(sourceLaneId, targetLaneId, cardId, position)
                            }
                        />
                        {isModalOpen && (
                            <Modal cardId={selectedCard} onClose={handleCloseModal} />
                        )}
                    </div>
                )}
                <div
                    style={{position: 'fixed', bottom: 10, right: 10}}
                    onMouseEnter={() => setMenuVisible(true)}
                >
                    <span className={"span-add-icon"} onClick={() => setMenuVisible(!menuVisible)}>
                        <i className="fa-solid fa-plus"></i>
                    </span>
                    {menuVisible && (
                        <div className={"list-add-items-trello"}>
                            <ul style={{listStyle: 'none', padding: '10px', margin: '0'}}>
                                <li className={"li-add-trello"}
                                    style={{padding: '10px 0', borderBottom: '1px solid #ddd'}}
                                    onClick={() => openModal('Ajout d\'une tâche', 'task')}>Ajouter une tâche
                                </li>
                                <li className={"li-add-trello"}
                                    style={{padding: '10px 0', borderBottom: '1px solid #ddd'}}
                                    onClick={() => openModal('Ajout d\'une colonne', 'column')}>Ajouter une colonne
                                </li>
                                <li className={"li-add-trello"} style={{padding: '10px 0'}}
                                    onClick={() => openModal('Ajout d\'un département', 'department')}>Ajouter un
                                    département
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                {modalVisible && (
                    <Fragment>
                        {/* Modal pour ajouter une tâche, un département ou une colonne */}
                        <ModalPlanification
                            closeModal={closeModal} modalInfo={modalInfo}
                            handleInputChange={handleInputChange} handleKeyPress={handleKeyPress}
                            setNewDepartment={setNewDepartment} departments={departments}
                            newDepartment={newDepartment} error={error} handleSave={handleSave}
                            handleAddDepartment={handleAddDepartment}
                        />
                    </Fragment>
                )}
            </Fragment>
        </div>
    );
};

export default Trello;
